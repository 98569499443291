import {
  Box,
  Button,
  Collapse,
  Flex,
  Text,
  Tooltip,
  useDisclosure,
  ButtonProps,
  TextProps,
} from '@chakra-ui/react';
import { ReactNode } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import AsteriskIcon from './AsteriskIcon';
import HelpIcon from '@mui/icons-material/Help';

type SectionCollapsableProps = {
  title: string;
  defaultIsOpen?: boolean;
  children: ReactNode;
  isRequired?: boolean;
  tooltip?: string;
  buttonProps?: ButtonProps;
  titleProps?: TextProps;
};

export function SectionCollapsable({
  title,
  defaultIsOpen = true,
  children,
  isRequired = false,
  tooltip,
  buttonProps,
  titleProps,
}: SectionCollapsableProps) {
  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen });
  return (
    <Box mx={-4}>
      <Button
        variant="outline"
        border="none"
        display="flex"
        px={4}
        w="100%"
        justifyContent="space-between"
        alignItems="center"
        onClick={onToggle}
        {...buttonProps}
      >
        <Text
          as="div"
          aria-label={title}
          variant="boldBlue"
          fontSize="xl"
          {...titleProps}
        >
          <Tooltip label={tooltip} placement="bottom-start">
            <Flex alignItems="center" gap={1}>
              <Flex>
                {title}
                <Box as="span" display="inline-flex">
                  {isRequired ? <AsteriskIcon width="6px" /> : null}
                </Box>
              </Flex>
              {tooltip ? <HelpIcon fontSize="small" /> : null}
            </Flex>
          </Tooltip>
        </Text>
        {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </Button>
      <Collapse
        in={isOpen}
        animateOpacity
        style={{ overflow: isOpen ? 'visible' : 'hidden' }}
      >
        <Box px={4}>{children}</Box>
      </Collapse>
    </Box>
  );
}
