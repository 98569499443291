import { useNavigate, useParams, Outlet } from 'react-router-dom';
import { ExceptionState } from 'components/ExceptionState';
import { AxiosError } from 'axios';
import { PurchaseOrderDetails } from 'features/purchase-order-details/PurchaseOrderDetails';
import { usePurchaseOrder } from 'queries/purchaseOrders/usePurchaseOrder';

export function PurchaseOrderDetailsPage() {
  const { purchaseOrderNumber } = useParams();
  const navigate = useNavigate();
  const {
    data: order,
    isLoading,
    error,
  } = usePurchaseOrder(purchaseOrderNumber!);

  const handleEdit = () => {
    navigate(`/inbound-inventory/${purchaseOrderNumber}/edit`);
  };

  const handleCancel = () => {
    navigate(`/inbound-inventory/${purchaseOrderNumber}/cancel`);
  };

  return (
    <>
      {(error as AxiosError)?.response?.status === 404 ? (
        <ExceptionState
          customTitle="Shipping Plan Not Found"
          stateType="empty"
        />
      ) : (
        <PurchaseOrderDetails
          order={isLoading ? undefined : order}
          onEdit={handleEdit}
          onCancel={handleCancel}
        />
      )}

      <Outlet />
    </>
  );
}
