import {
  Box,
  SimpleGrid,
  Text,
  Textarea,
  useControllableState,
} from '@chakra-ui/react';

type Props = {
  onChange?: (value: string) => void;
};

export function BulkCancelInput({ onChange }: Props) {
  const [value, setValue] = useControllableState({
    onChange,
  });

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(event.target.value);
  };

  return (
    <SimpleGrid column={1} gap={5}>
      <Box>
        <Text>Enter a list of shipments with each on a new line</Text>
      </Box>
      <Box>
        <Text variant="pageHeading">Shipment Numbers</Text>
        <Textarea mt={4} value={value} onChange={handleChange} />
      </Box>
    </SimpleGrid>
  );
}
