/* istanbul ignore file */
import { ChakraProvider } from '@chakra-ui/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { theme } from 'theme/theme';
import { ConfirmationDialogContextProvider } from 'contexts/confirmationDialogContext';
import { toastOptions } from 'theme/toast-options';
import { Router } from 'Router';

const CACHE_STALE_TIME = 90000;
const THREE_SECONDS = 3000;
const MAX_RETRIES = 2;

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: CACHE_STALE_TIME,
      retry: (failureCount, error) => {
        return (
          failureCount < MAX_RETRIES && (error as any).response.status !== 404
        );
      },
      retryDelay: THREE_SECONDS,
    },
  },
});

export const App = () => (
  <QueryClientProvider client={queryClient}>
    <ChakraProvider theme={theme} toastOptions={toastOptions}>
      <ConfirmationDialogContextProvider>
        <Router />
      </ConfirmationDialogContextProvider>
    </ChakraProvider>
  </QueryClientProvider>
);
