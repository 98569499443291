import { HStack, Button, Text } from '@chakra-ui/react';
import { PaginatedTable } from 'components/PaginatedTable';
import { useMemo } from 'react';
import { QueryState } from 'types/queryState';
import { FreightLabelDetail } from 'types/salesOrders';
import { downloadReport } from 'utils/reportUtils';
import { stringify } from 'csv-stringify/browser/esm/sync';
import { FileType } from 'utils/fileTypes';

const MAX_ITEMS = 10;

type Props = {
  soNumber: string;
  data: FreightLabelDetail[];
};

export function LabelDetailsTable({ soNumber, data }: Props) {
  const columns = useMemo(
    () => [
      {
        Header: 'SSCC Code',
        accessor: 'ssccCode',
        width: 250,
      },
      {
        Header: 'SKU',
        accessor: 'sku',
        width: 250,
      },
      {
        Header: 'Quantity',
        accessor: 'qty',
        width: 150,
      },
      {
        Header: 'Carton #',
        accessor: 'cartonNum',
        width: 150,
      },
    ],
    []
  );

  const exportCSV = () => {
    const csvData = data.map((item) => ({
      ...item,
      sku: `"${item.sku}"`,
      ssccCode: `"${item.ssccCode}"`,
    }));
    downloadReport({
      fileName: `${soNumber}-case-labels.csv`,
      data: stringify(Object.values(csvData), {
        header: true,
        columns: {
          ssccCode: 'SSCC Code',
          sku: 'SKU',
          qty: 'Quantity',
          cartonNum: 'Carton #',
        },
      }),
      fileType: FileType.Csv,
    });
  };

  return (
    <>
      <PaginatedTable
        aria-label="Label Details Table"
        columns={columns}
        queryState={
          {
            data: data.slice(0, MAX_ITEMS),
            isLoading: !data,
          } as QueryState<FreightLabelDetail>
        }
        tableName="label_details_table"
        isResizable
      />
      <HStack spacing={4} align="center">
        <Text>
          {data.length > MAX_ITEMS ? MAX_ITEMS : data.length} of {data.length}{' '}
          records shown
        </Text>
        <Button variant="outline" size="md" onClick={exportCSV}>
          Download All
        </Button>
      </HStack>
    </>
  );
}
