import {
  Box,
  Flex,
  SimpleGrid,
  Stat,
  StatLabel,
  StatNumber,
} from '@chakra-ui/react';
import { ExceptionState } from 'components/ExceptionState';
import { Shimmer } from 'components/Shimmer';
import { StatLink } from 'components/StatLink';
import { useOrderClosedSummary } from 'queries/salesOrders/useOrderClosedSummary';
import { SalesOrderStatus } from 'types/salesOrders';

export const ClosedSalesOrders = () => {
  const { data: summary, isLoading, error, refetch } = useOrderClosedSummary();

  const totalClosed =
    (summary?.totalShipped ?? 0) +
    (summary?.totalShippedPartial ?? 0) +
    (summary?.totalShippedShort ?? 0);

  return (
    <Box py={5}>
      {!isLoading && !!error ? (
        <ExceptionState stateType="error" retryHandler={refetch} />
      ) : (
        <Flex
          textAlign="center"
          gap={4}
          flexDirection={{ base: 'column', md: 'row' }}
        >
          <Stat size="8xl" variant="primary">
            <Shimmer h="90px" my={8} isLoaded={!isLoading}>
              <StatNumber>
                {totalClosed.toLocaleString?.('en-US') ?? 0}
              </StatNumber>
            </Shimmer>
            <StatLabel>Total Closed Shipments</StatLabel>
          </Stat>
          <SimpleGrid columns={2} flex={1} gap={1}>
            <StatLink
              label="Shipped"
              tooltip="All units for all line items have shipped. This order is closed."
              number={summary?.totalShipped ?? 0}
              to={`/orders?latest_status=${SalesOrderStatus.Shipped}`}
              isLoading={isLoading}
            />
            <StatLink
              label="Shipped Partial"
              tooltip="At least 1 unit has shipped but not all units. This order is closed."
              number={summary?.totalShippedPartial ?? 0}
              to={`/orders?latest_status=${SalesOrderStatus.ShippedPartial}`}
              isLoading={isLoading}
            />
            <StatLink
              label="Shipped Short"
              tooltip="At least 1 unit has shipped but not all units. This order could still be fulfilled later."
              number={summary?.totalShippedShort ?? 0}
              to={`/orders?latest_status=${SalesOrderStatus.ShippedShort}`}
              isLoading={isLoading}
            />
          </SimpleGrid>
        </Flex>
      )}
    </Box>
  );
};
