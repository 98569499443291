import { Box, BoxProps, HStack, Tag, Text } from '@chakra-ui/react';
import { InlineText } from 'components/InlineText';
import { Shimmer } from 'components/Shimmer';
import { StatusHistoryEntry } from 'types/status';
import { formatDate } from 'utils/dateUtils';

type Props = BoxProps & {
  history?: StatusHistoryEntry<any>[];
  latestStatusColor: string;
};

export function StatusChangeList({ history, latestStatusColor }: Props) {
  const currentStatus = history?.at(-1);
  const previousStatuses = history?.slice(0, -1)?.reverse();

  return (
    <Box>
      <Text variant="boldBlue" fontSize="xl">
        Status
      </Text>
      <HStack py={1} spacing={2} flexWrap="wrap">
        <Shimmer
          w="160px"
          h="30px"
          display="inline-block"
          verticalAlign="middle"
          isLoaded={!!history}
        >
          <Tag
            aria-label={`Current status - ${latestStatusColor}`}
            size="lg"
            fontSize="lg"
            variant="solid"
            colorScheme={latestStatusColor}
          >
            {currentStatus?.status}
          </Tag>
          <Text fontSize="xl" color="primaryBlue.500" fontWeight="500">
            {formatDate(currentStatus?.status_date)}
          </Text>
          {currentStatus?.error_type ? (
            <Box pl={8} flex="1 0 100%">
              <Tag size="lg" fontSize="lg" variant="solid" colorScheme="red">
                {currentStatus?.error_type
                  ?.replace(/-/g, ' ')
                  ?.replace(/^\w|\s\w/g, (c) => c.toUpperCase())}
              </Tag>
              <Text mt={1} fontWeight="500">
                {currentStatus?.error_msg}
              </Text>
            </Box>
          ) : null}
        </Shimmer>
      </HStack>
      <Box as="ul" pl={4}>
        {previousStatuses?.map(({ status, status_date }) => (
          <Box as="li" key={`${status}_${status_date}`}>
            <Shimmer
              w="160px"
              h="30px"
              display="inline-block"
              verticalAlign="middle"
              isLoaded={!!history}
            >
              <InlineText mr={2}>{status}</InlineText>
              <InlineText>{formatDate(status_date)}</InlineText>
            </Shimmer>
          </Box>
        ))}
      </Box>
    </Box>
  );
}
