import { Invitation, InvitationDto } from 'types/invitation';
import { User, UserDto } from 'types/user';

export function mapDtoToUser(userDto: UserDto): User {
  return {
    id: userDto.user_id,
    emailAddress: userDto.email,
    name: userDto.name,
    picture: userDto.picture,
    roles: userDto.roles || [],
    rolesLabel: userDto.roles?.length
      ? userDto.roles?.join(', ')
      : 'Standard User',
  };
}

export function mapDtoToInvitation(invitationDto: InvitationDto): Invitation {
  return {
    createdAt: invitationDto.created_at,
    expiresAt: invitationDto.expires_at,
    emailAddress: invitationDto.email_address,
    id: invitationDto.id,
    invitationUrl: invitationDto.invitation_url,
  };
}
