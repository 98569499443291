import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { ItemMasterDto } from 'types/itemMaster';
import { mapDtoToItemMaster } from './mappers';

export function useItemMaster(sku: string) {
  return useQuery(
    ['itemMaster', sku],
    async () => {
      const { data } = await axios.get<ItemMasterDto>(
        `/itemmaster/${encodeURIComponent(sku)}`
      );

      return data;
    },
    {
      select: mapDtoToItemMaster,
      enabled: Boolean(sku),
    }
  );
}
