import { AxiosError } from 'axios';
import { BigLoading } from 'components/BigLoading';
import { ErrorBoundary } from 'components/ErrorBoundary';
import { ExceptionState } from 'components/ExceptionState';
import { Modal } from 'components/Modal';
import { CancelPurchaseOrderPrompt } from 'features/purchase-order-details/components/CancelPurchaseOrder/CancelPurchaseOrderPrompt';
import { usePurchaseOrder } from 'queries/purchaseOrders/usePurchaseOrder';
import { useNavigate, useParams } from 'react-router-dom';

export function CancelPurchaseOrderPage() {
  const { purchaseOrderNumber } = useParams();
  const navigate = useNavigate();
  const { data: order, error } = usePurchaseOrder(purchaseOrderNumber!);

  const handleAbort = () => navigate(-1);

  const handleConfirm = () => navigate('/inbound-inventory');

  return (
    <Modal
      isOpen={true}
      onClose={handleAbort}
      size={['full', null, '2xl']}
      modalBodyProps={{
        display: ['flex', null, 'block'],
      }}
    >
      <ErrorBoundary>
        {(error as AxiosError)?.response?.status === 404 ? (
          <ExceptionState
            customTitle="Shipping Plan Not Found"
            stateType="empty"
          />
        ) : order ? (
          <CancelPurchaseOrderPrompt
            order={order}
            onConfirm={handleConfirm}
            onAbort={handleAbort}
          />
        ) : (
          <BigLoading />
        )}
      </ErrorBoundary>
    </Modal>
  );
}
