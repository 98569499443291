import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  Outlet,
} from 'react-router-dom';
import { StoreProvider } from 'contexts/storeContext';
import { Auth0ProviderWithRedirectCallback } from 'auth0-provider';
import { OrderDetailsPage } from 'pages/OrderDetailsPage';
import { CreateOrderPage } from 'pages/CreateOrderPage';
import { PurchaseOrderDetailsPage } from 'pages/PurchaseOrderDetailsPage';
import { NotImplementedPage } from 'pages/NotImplementedPage';
import { OrdersPage } from 'pages/OrdersPage';
import { DashboardPage } from 'pages/DashboardPage';
import { AdminPage } from 'pages/AdminPage';
import { InventoryPage } from 'pages/InventoryPage';
import { InventoryItemDetailsPage } from 'pages/InventoryItemDetailsPage';
import { PurchaseOrderPage } from 'pages/PurchaseOrderPage';
import { SchedulePurchaseOrderPage } from 'pages/SchedulePurchaseOrderPage';
import { MainLayout } from 'layouts/MainLayout';
import { ProtectedRoute } from 'components/ProtectedRoute';
import { AsnPage } from 'pages/AsnPage';
import { ReturnsPage } from 'pages/ReturnsPage';
import { CONFIG } from 'utils/config';
import TemplatesPage from 'pages/TemplatesPage';
import { ItemMastersPage } from 'pages/ItemMastersPage';
import { UploadItemMastersPage } from 'pages/UploadItemMastersPage';
import { MsaDocumentsPage } from 'pages/MsaDocumentsPage';
import { TransportationDetailsPage } from 'pages/TransportationDetailsPage';
import { AUTH0_ROLES } from 'constants/auth0Roles';
import { ReRouteOrderPage } from 'pages/ReRouteOrderPage';
import { BulkCancelPage } from 'pages/BulkCancelPage';
import { NetworkOrdersPage } from 'pages/NetworkOrdersPage';
import { NetworkOrderDetailsPage } from 'pages/NetworkOrderDetailsPage';
import { useTenant } from 'queries/tenant/useTenant';
import { BigLoading } from 'components/BigLoading';
import { ModifyOrderPage } from 'pages/ModifyOrderPage';
import { CancelOrderPage } from 'pages/CancelOrderPage';
import { ModifyPurchaseOrderPage } from 'pages/ModifyPurchaseOrderPage';
import { CancelPurchaseOrderPage } from 'pages/CancelPurchaseOrderPage';

export const Router = () => {
  const { data: tenant, isLoading: isLoadingTenant } = useTenant();

  return (
    <BrowserRouter>
      <StoreProvider>
        <Auth0ProviderWithRedirectCallback>
          {isLoadingTenant ? (
            <BigLoading />
          ) : (
            <Routes>
              <Route
                path="/"
                element={<ProtectedRoute component={MainLayout} />}
              >
                <Route index element={<Navigate replace to="dashboard" />} />
                <Route
                  path="dashboard"
                  element={<ProtectedRoute component={DashboardPage} />}
                >
                  <Route path=":salesOrderNumber">
                    <Route
                      path="edit"
                      element={<ProtectedRoute component={OrderDetailsPage} />}
                    />
                    <Route
                      path="cancel"
                      element={<ProtectedRoute component={OrderDetailsPage} />}
                    />
                    <Route
                      path=""
                      element={<ProtectedRoute component={OrderDetailsPage} />}
                    />
                  </Route>
                </Route>
                {CONFIG().isNetworkOrderEnabled(tenant) ? (
                  <Route
                    path="network-orders"
                    element={<ProtectedRoute component={NetworkOrdersPage} />}
                  >
                    <Route path=":orderName">
                      <Route
                        path=""
                        element={
                          <ProtectedRoute component={NetworkOrderDetailsPage} />
                        }
                      />
                    </Route>
                  </Route>
                ) : null}
                <Route path="orders">
                  <Route
                    path=""
                    element={<ProtectedRoute component={OrdersPage} />}
                  >
                    <Route
                      path="bulk-cancel"
                      element={
                        <ProtectedRoute
                          component={BulkCancelPage}
                          requireOneOfRoles={[AUTH0_ROLES.STANDVAST_ADMIN]}
                        />
                      }
                    />
                  </Route>
                  <Route path=":salesOrderNumber">
                    <Route
                      path=""
                      element={<ProtectedRoute component={OrderDetailsPage} />}
                    >
                      <Route
                        path="re-route"
                        element={
                          <ProtectedRoute
                            component={ReRouteOrderPage}
                            requireOneOfRoles={[AUTH0_ROLES.STANDVAST_ADMIN]}
                          />
                        }
                      />
                      <Route
                        path="cancel"
                        element={<ProtectedRoute component={CancelOrderPage} />}
                      />
                    </Route>
                    <Route
                      path="edit"
                      element={<ProtectedRoute component={ModifyOrderPage} />}
                    />
                  </Route>
                  {CONFIG().featureToggles.enableCreateSalesOrder ? (
                    <Route
                      path="create"
                      element={<ProtectedRoute component={CreateOrderPage} />}
                    />
                  ) : null}
                </Route>
                <Route path="inventory">
                  <Route
                    path=""
                    element={<ProtectedRoute component={InventoryPage} />}
                  />
                  <Route
                    path=":sku"
                    element={
                      <ProtectedRoute component={InventoryItemDetailsPage} />
                    }
                  />
                </Route>
                <Route
                  path="admin"
                  element={
                    <ProtectedRoute
                      component={AdminPage}
                      requireOneOfRoles={[
                        AUTH0_ROLES.SUPER_USER,
                        AUTH0_ROLES.STANDVAST_ADMIN,
                      ]}
                    />
                  }
                />
                <Route path="inbound-inventory">
                  <Route
                    path=""
                    element={<ProtectedRoute component={PurchaseOrderPage} />}
                  />
                  <Route path=":purchaseOrderNumber">
                    <Route
                      path="edit"
                      element={
                        <ProtectedRoute component={ModifyPurchaseOrderPage} />
                      }
                    />
                    <Route
                      path=""
                      element={
                        <ProtectedRoute component={PurchaseOrderDetailsPage} />
                      }
                    >
                      <Route
                        path="cancel"
                        element={
                          <ProtectedRoute component={CancelPurchaseOrderPage} />
                        }
                      />
                    </Route>
                  </Route>
                  <Route
                    path="schedule"
                    element={
                      <ProtectedRoute component={SchedulePurchaseOrderPage} />
                    }
                  />
                </Route>
                <Route
                  path="team-admin"
                  element={<ProtectedRoute component={NotImplementedPage} />}
                />
                {CONFIG().featureToggles.enableAsnReport ? (
                  <Route
                    path="/report/asns"
                    element={<ProtectedRoute component={AsnPage} />}
                  />
                ) : null}
                {CONFIG().featureToggles.enableTransportationDetailsReport ? (
                  <Route
                    path="/report/transportation-details"
                    element={
                      <ProtectedRoute
                        component={TransportationDetailsPage}
                        requireOneOfRoles={[
                          AUTH0_ROLES.STANDVAST_ADMIN,
                          AUTH0_ROLES.FINANCIAL_DATA_ACCESS,
                        ]}
                      />
                    }
                  />
                ) : null}
                {CONFIG().featureToggles.enableReturnsReport ? (
                  <Route
                    path="/report/returns"
                    element={<ProtectedRoute component={ReturnsPage} />}
                  />
                ) : null}
                <Route
                  path="templates"
                  element={<ProtectedRoute component={TemplatesPage} />}
                />
                {CONFIG().featureToggles.enableItemMaster ? (
                  <Route
                    path="item-masters"
                    element={<ProtectedRoute component={Outlet} />}
                  >
                    <Route
                      index
                      element={<ProtectedRoute component={ItemMastersPage} />}
                    />
                    <Route
                      path="create"
                      element={
                        <ProtectedRoute
                          component={UploadItemMastersPage}
                          requireOneOfRoles={[AUTH0_ROLES.STANDVAST_ADMIN]}
                        />
                      }
                    />
                    <Route
                      path="update"
                      element={
                        <ProtectedRoute
                          component={UploadItemMastersPage}
                          requireOneOfRoles={[AUTH0_ROLES.STANDVAST_ADMIN]}
                        />
                      }
                    />
                  </Route>
                ) : null}
                <Route
                  path="msa"
                  element={
                    <ProtectedRoute
                      component={MsaDocumentsPage}
                      requireOneOfRoles={[
                        AUTH0_ROLES.STANDVAST_ADMIN,
                        AUTH0_ROLES.FINANCIAL_DATA_ACCESS,
                      ]}
                    />
                  }
                />
              </Route>
              <Route
                path="*"
                element={<ProtectedRoute component={NotImplementedPage} />}
              />
            </Routes>
          )}
        </Auth0ProviderWithRedirectCallback>
      </StoreProvider>
    </BrowserRouter>
  );
};
