import { useNavigate, useLocation } from 'react-router-dom';
import { CONFIG } from 'utils/config';
import { usePortal } from 'hooks/usePortal';
import { SchedulePurchaseOrder } from 'features/schedule-purchase-order/SchedulePurchaseOrder';
import { PurchaseOrder } from 'types/purchaseOrders';
import { useState } from 'react';
import { SuccessSchedulePurchaseOrderModal } from 'features/schedule-purchase-order/SuccessSchedulePurchaseOrderModal';

export function SchedulePurchaseOrderPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const urlSegments = location.pathname.split('/');
  const firstUrlSegment = urlSegments[1] ?? '';
  const { isPortalReadOnly } = usePortal();
  const [purchaseOrder, setPurchaseOrder] = useState<PurchaseOrder>();

  const handleClose = () => {
    navigate(`/${firstUrlSegment}`);
  };

  return CONFIG().featureToggles.enableSchedulePurchaseOrder &&
    !isPortalReadOnly ? (
    <>
      <SchedulePurchaseOrder
        onAbort={handleClose}
        onConfirm={setPurchaseOrder}
      />
      {purchaseOrder ? (
        <SuccessSchedulePurchaseOrderModal
          data={purchaseOrder}
          onClose={handleClose}
        />
      ) : null}
    </>
  ) : null;
}
