import { SpaceProps, Link, Tooltip, Box } from '@chakra-ui/react';
import { CopyableText } from 'components/CopyableText';
import { Flyout } from 'components/Flyout';
import { Fragment, useMemo } from 'react';
import {
  FreightSalesOrderLine,
  SalesOrder,
  SalesOrderLineBase,
} from 'types/salesOrders';
import { getCarrierTrackingURL } from 'utils/salesOrdersUtils';

import { PaginatedTable } from 'components/PaginatedTable';
import { Column } from 'react-table';
import { QueryState } from 'types/queryState';
import { useLayoutPaginatedTable } from 'hooks/useLayoutPaginatedTable';

interface Props extends SpaceProps {
  order?: SalesOrder;
}

export function OrderLinesTable({ order }: Props) {
  const hasSomeBundleSku = order?.lines?.some((line) => !!line.bundleSku);
  const isFreightOrder = order?.type === 'Freight';

  const { getColumnWidth } = useLayoutPaginatedTable(
    isFreightOrder ? 1455 : 1150
  );

  const orderLinesColumns = useMemo(
    () =>
      [
        {
          Header: 'Line #',
          accessor: 'lineNo',
          width: getColumnWidth(5, 70),
        },
        hasSomeBundleSku
          ? {
              Header: 'Bundle SKU',
              accessor: 'bundleSku',
              width: getColumnWidth(10, 150),
            }
          : null,
        {
          Header: 'SKU',
          accessor: 'sku',
          width: getColumnWidth(10, 150),
        },
        {
          Header: 'Tracking #',
          accessor: 'trackingNumbers',
          width: getColumnWidth(15, 245),
          Cell: ({ value: trackingNumbers }: { value: string[] }) =>
            trackingNumbers && trackingNumbers.length > 0 ? (
              <CopyableText
                children={trackingNumbers.map((tn, i) => {
                  const url = getCarrierTrackingURL(order?.shipVia, tn);
                  return (
                    <Fragment key={i}>
                      {url ? (
                        <Link href={url} target="_blank" rel="noreferrer">
                          {tn}
                        </Link>
                      ) : (
                        tn
                      )}
                      {i < trackingNumbers.length - 1 ? ' ' : ''}
                    </Fragment>
                  );
                })}
                text={trackingNumbers.join(', ')}
                copiedMessage={`Tracking number${
                  trackingNumbers.length > 1 ? 's' : ''
                } copied.`}
                isExpandable={trackingNumbers.length > 1}
                titleButton="Copy All"
              />
            ) : null,
        },
        {
          Header: 'Customer Item #',
          accessor: 'customerItemNumber',
          width: getColumnWidth(15, 160),
          Cell: ({ value: customerItemNumber }: { value: string }) => (
            <Flyout w="150px">{customerItemNumber}</Flyout>
          ),
        },
        isFreightOrder
          ? {
              Header: 'ARN',
              accessor: 'arn',
              width: getColumnWidth(5, 150),
              Cell: ({ value: arn }: { value?: string }) => (
                <Flyout w="120px">
                  <Tooltip label={arn || ''}>{arn || ''}</Tooltip>
                </Flyout>
              ),
            }
          : null,
        isFreightOrder
          ? {
              Header: 'ASN',
              accessor: 'asn',
              width: getColumnWidth(5, 150),
              Cell: ({ value: asn }: { value?: string }) => (
                <Flyout w="120px">
                  <Tooltip label={asn || ''}>{asn || ''}</Tooltip>
                </Flyout>
              ),
            }
          : null,
        {
          Header: 'Description',
          accessor: 'description',
          width: getColumnWidth(isFreightOrder ? 20 : 31, 200),
          Cell: ({ value }: { value: string }) => (
            <Box whiteSpace="normal">{value}</Box>
          ),
        },
        {
          Header: 'Order Qty',
          accessor: 'orderQty',
          width: getColumnWidth(8, 110),
        },
        {
          Header: 'Ship Qty',
          accessor: 'shippedQty',
          width: getColumnWidth(8, 110),
        },
        {
          Header: 'Short Qty',
          accessor: 'shortQty',
          width: getColumnWidth(8, 110),
        },
      ].filter(Boolean) as Column<any>[],
    // eslint-disable-next-line
    [order, getColumnWidth]
  );

  return (
    <PaginatedTable
      columns={orderLinesColumns}
      queryState={
        {
          data: order?.lines || [],
          isLoading: !order,
        } as QueryState<SalesOrderLineBase | FreightSalesOrderLine>
      }
      tableName="order_lines_table"
      isResizable
      isSticky
    />
  );
}
