import { Box, Tag } from '@chakra-ui/react';
import { useMemo } from 'react';
import { PaginatedTable } from 'components/PaginatedTable';
import { QueryState } from 'types/queryState';
import { NetworkOrderDetails, NetworkOrderLineItem } from 'types/networkOrder';
import { Column, Row } from 'react-table';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import NetworkOrderLineDetailsTable from './NetworkOrderLineDetailsTable';
import { lineItemHasBundle } from 'utils/networkOrdersUtils';

interface Props {
  order?: NetworkOrderDetails;
}

export function NetworkOrderLinesTable({ order }: Props) {
  const columns = useMemo(
    () =>
      [
        {
          Header: 'Line #',
          accessor: 'lineNumber',
          width: 100,
        },
        {
          Header: 'SKU',
          accessor: 'sku',
          width: 300,
          Cell: ({ value, row }: { value: string; row: any }) => (
            <Box display="flex">
              {value}
              {lineItemHasBundle(row.original) ? (
                <Box {...row.getToggleRowExpandedProps()} display="flex">
                  <Tag ml={2} size="sm" variant="solid" colorScheme="green">
                    BUNDLE
                  </Tag>
                  {row.isExpanded ? (
                    <ExpandMoreIcon fontSize="small" />
                  ) : (
                    <ChevronRightIcon fontSize="small" />
                  )}
                </Box>
              ) : null}
            </Box>
          ),
        },
        {
          Header: 'Order Qty',
          accessor: 'qtyRequested',
          width: 250,
        },
      ] as Column<NetworkOrderLineItem>[],
    []
  );

  return (
    <PaginatedTable
      columns={columns}
      queryState={
        {
          data: order?.lineItems || [],
          isLoading: !order,
        } as QueryState<NetworkOrderLineItem>
      }
      tableName="network_order_lines_table"
      isResizable
      isSticky
      renderSubRow={(row: Row<NetworkOrderLineItem>) => (
        <NetworkOrderLineDetailsTable
          orderQty={row.original.qtyRequested}
          data={row.original.childSkus ?? []}
        />
      )}
    />
  );
}
