import { PaginatedResponse } from './paginatedResponse';
import { AddressInfo, AddressInfoDto } from './address';
import { StatusHistoryEntry } from './status';

export enum PurchaseOrderStatus {
  Created = 'Created',
  ReceivingStarted = 'Receiving Started',
  Received = 'Received',
}

export type PurchaseOrderLine = {
  lineNo: string;
  sku: string;
  description: string;
  orderQty: number;
  receivedQty: number;
  shortQty: number;
  damagedQty: number;
  overageQty: number;
  unitOfMeasure: string;
  customerItemNumber: string;
};

export type PurchaseOrder = {
  poNumber: string;
  status: PurchaseOrderStatus;
  statusDate: string;
  createdDate: string;
  requestedDate: string;
  vendorName: string;
  containerNumber?: string;
  containerNumbers: { containerNumber: string }[];
  warehouseID: string;
  totalLines: number;
  totalOrderQty: number;
  totalReceivedQty: number;
  totalShortQty: number;
  totalDamagedQty: number;
  totalOverageQty: number;
  lines: PurchaseOrderLine[];
  statusChangesHistory: StatusHistoryEntry<PurchaseOrderStatus>[];
  deliveryAddress?: Omit<AddressInfo, 'address2'>;
  containerStatus?: string[];
};

export type PurchaseOrderSearchResult = {
  poNumber: string;
};

type PurchaseOrderDtoCommon = {
  po_number: string;
  vendor_name: string;
  container_number?: string;
  container_numbers: string[];
  requested_delivery_date: string;
  target_dc: string;
  created_date: string;
  container_status?: string[];
};

export type PurchaseOrderLineDto = {
  line_number: string;
  sku: string;
  description: string;
  uom: string;
  external_item_number: string;
  order_qty: number;
  received_qty: number;
  short_qty: number;
  damaged_qty: number;
  overage_qty: number;
};

export type PurchaseOrderCondensedDto = PurchaseOrderDtoCommon & {
  latest_status: PurchaseOrderStatus;
  status_date: PurchaseOrderStatus;
  total_order_qty: number;
  total_received_qty: number;
  total_short_qty: number;
  total_damaged_qty: number;
  total_overage_qty: number;
  total_po_lines: number;
};

export type PurchaseOrderDetailDto = PurchaseOrderDtoCommon & {
  purchase_order_lines: PurchaseOrderLineDto[];
  delivery_address?: Omit<AddressInfoDto, 'address_2'>;
  status_history: {
    status: string;
    date: string;
    status_error_message: string;
  }[];
};

export type PurchaseOrderDto =
  | PurchaseOrderCondensedDto
  | PurchaseOrderDetailDto;

export type PurchaseOrderSearchResultDto = {
  po_number: string;
};

export type PaginatedPurchaseOrdersResponseDto = PaginatedResponse & {
  items: PurchaseOrderCondensedDto[];
};

export type ContainerStatus = {
  number: string;
  status: 'Checked-in' | 'Pending';
};
