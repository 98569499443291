/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useState } from 'react';
import { GridItem, SimpleGrid, Text } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider } from 'react-hook-form';
import { ButtonGroup } from 'components/ButtonGroup';
import { useConfirmationDialog } from 'hooks/useConfirmationDialog';
import { useUpdateSalesOrder } from 'mutations/salesOrders';
import { SalesOrder } from 'types/salesOrders';
import { modifyOrderSchema } from 'schemas/modifyOrder';
import { OrderLines } from 'features/order-lines/OrderLines';
import { ShipToForm } from './ShipToForm';
import { useCrudActionUserFeedback } from 'hooks/useCrudActionUserFeedback';
import { mapFieldDtoErrorToRhfPath } from 'mutations/salesOrders/mappers';
import { sendUpdateSalesOrderEvent } from 'utils/monitorEvents';
import { DatesForm } from './DatesForm';
import { CodesForm } from './CodesForm';
import { ShipViaForm } from './ShipViaForm';
import { useSalesOrderForm } from 'hooks/useSalesOrderForm';
import { GiftMessageForm } from 'features/create-order/GiftMessageForm';
import { BolBillingAddressForm } from 'features/create-order/BolBillingAddressForm';
import { BolDataForm } from 'features/create-order/BolDataForm';
import { StorefrontSelect } from 'features/create-order/StorefrontSelect';
import CarrierAccountSelect from 'features/create-order/CarrierAccountSelect';
import { SectionCollapsable } from 'components/SectionCollapsable';

type Props = {
  order: SalesOrder;
  onClose: () => void;
};

export function ModifyOrder({ order, onClose }: Props) {
  const [isProcessing, setIsProcessing] = useState(false);
  const { openConfirmationDialog } = useConfirmationDialog();

  const reactHookFormRet = useSalesOrderForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: order,
    resolver: yupResolver(modifyOrderSchema),
  });

  const {
    handleSubmit,
    getValues,
    formState: { isDirty, isSubmitting },
  } = reactHookFormRet;

  const isFreightOrder = order.type === 'Freight';
  const bolTooltipMessage =
    'This information will be automatically updated upon carrier pickup completion.';

  const confirmAbort = useCallback(
    (callback: () => void) => {
      if (isDirty) {
        openConfirmationDialog({
          title: 'Discard changes',
          message: `Discard changes to #${order.soNumber} before leaving?`,
          onConfirm: callback,
          blockScrollOnMount: false,
          confirmButtonLabel: 'Discard',
        });
      } else {
        callback();
      }
    },
    [isDirty, order.soNumber]
  );

  const { mutateAsync } = useUpdateSalesOrder(order.soNumber);

  const handleUpdateOrder = useCrudActionUserFeedback<SalesOrder>({
    mutateAsync: () => mutateAsync(getValues()),
    actionType: 'UPDATE',
    successMessage: `Shipment #${order.soNumber} successfully updated`,
    successCallback: () => {
      sendUpdateSalesOrderEvent(order.soNumber);
      onClose();
    },
    startCallback: () => {
      setIsProcessing(true);
    },
    finallyCallback: () => {
      setIsProcessing(false);
    },
    setFieldError: reactHookFormRet.setError,
    mapFieldDtoErrorToRhfPath,
  });

  if (order.type === 'Parcel' && !order.carrierAccountUUID) return null;

  return (
    <FormProvider {...reactHookFormRet}>
      <Text as="h2" variant="pageTitle">
        Modify Shipment #{order.soNumber}
      </Text>
      <form onSubmit={handleSubmit(handleUpdateOrder)}>
        <SimpleGrid columns={1}>
          <GridItem>
            <ShipToForm startTabIndex={0} />
          </GridItem>
          {isFreightOrder ? (
            <>
              <GridItem>
                <SimpleGrid columns={[1, 2]} columnGap={8}>
                  <StorefrontSelect tabIndex={10} />
                  <DatesForm startTabIndex={10} />
                  <CodesForm startTabIndex={11} />
                </SimpleGrid>
              </GridItem>
              <GridItem mt={8}>
                <SectionCollapsable
                  title="BOL Data"
                  defaultIsOpen={false}
                  tooltip={bolTooltipMessage}
                >
                  <BolDataForm startTabIndex={13} editable={false} />
                </SectionCollapsable>
              </GridItem>
              <GridItem mt={8}>
                <SectionCollapsable
                  title="BOL Billing Address"
                  defaultIsOpen={false}
                  tooltip={bolTooltipMessage}
                >
                  <BolBillingAddressForm startTabIndex={17} editable={false} />
                </SectionCollapsable>
              </GridItem>
            </>
          ) : (
            <GridItem>
              <SimpleGrid columns={[1, 2]} columnGap={8}>
                <StorefrontSelect tabIndex={10} />
                <ShipViaForm startTabIndex={10} />
                <CarrierAccountSelect tabIndex={13} />
              </SimpleGrid>
              <SimpleGrid columns={1} mt={8}>
                <GiftMessageForm tabIndex={14} />
              </SimpleGrid>
            </GridItem>
          )}
          <GridItem mt={8}>
            <OrderLines
              startTabIndex={25}
              title="SKUs"
              isFreightOrder={isFreightOrder}
            />
          </GridItem>
          <ButtonGroup
            wrapperProps={{
              mt: 8,
              display: 'grid',
              columnGap: 8,
              rowGap: 4,
              gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
            }}
            commonButtonWrapperProps={{ mr: 0, colSpan: 1 }}
            commonButtonProps={{ width: '100%' }}
            buttons={[
              {
                label: 'Update',
                variant: 'cta',
                isLoading: isProcessing || isSubmitting,
              },
              {
                label: 'Cancel',
                handler: () => confirmAbort(onClose),
              },
            ]}
          />
        </SimpleGrid>
      </form>
    </FormProvider>
  );
}
