/* istanbul ignore file */
import * as yup from 'yup';
import { orderLinesSchema } from './formParts/orderLines';

export const schedulePurchaseOrderSchema = yup.object({
  poNumber: yup.string().label('Shipping Plan #').required().max(50),
  warehouseID: yup.string().label('FC').required(),
  requestedDate: yup.date().label('Delivery Date').required(),
  lines: orderLinesSchema,
});
