/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Button,
  Flex,
  GridItem,
  SimpleGrid,
  Tab,
  TabList,
  Tabs,
  Text,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider } from 'react-hook-form';
import { ButtonGroup } from 'components/ButtonGroup';
import { SalesOrder, SalesOrderType } from 'types/salesOrders';
import { createFreightSalesOrderSchema } from 'schemas/createFreightSalesOrder';
import { createParcelSalesOrderSchema } from 'schemas/createParcelSalesOrder';
import { useCreateSalesOrder } from 'mutations/salesOrders/useCreateSalesOrder';
import { CreateParcelOrderForm } from 'features/create-order/CreateParcelOrderForm';
import { CreateFreightOrderForm } from 'features/create-order/CreateFreightOrderForm';
import { useCrudActionUserFeedback } from 'hooks/useCrudActionUserFeedback';
import { mapFieldDtoErrorToRhfPath } from 'mutations/salesOrders/mappers';
import RestoreIcon from '@mui/icons-material/Restore';
import { useConfirmationDialog } from 'hooks/useConfirmationDialog';
import {
  buildInittialLines,
  buildEmptyOrder,
} from 'queries/salesOrders/mappers';
import { sendCreateSalesOrderEvent } from 'utils/monitorEvents';
import { useSalesOrderForm } from 'hooks/useSalesOrderForm';
import { useLocation, useNavigate } from 'react-router-dom';
import merge from 'lodash-es/merge';
import { useShipVias } from 'queries/shipVias/useShipVias';
import { BasicInfoForm } from 'features/create-order/BasicInfoForm';
import { InstructionsForm } from 'features/create-order/InstructionsForm';
import { OrderLines } from 'features/order-lines/OrderLines';
import { ShipToForm } from 'features/order-details/components/ModifyOrder/ShipToForm';

const sessionStorageKey = 'create_so_draft';

const tabs = [
  { type: 'Parcel', label: 'Parcel Order', isSelected: true },
  { type: 'Freight', label: 'Freight Order' },
] as const;

export function CreateOrderPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const { openConfirmationDialog } = useConfirmationDialog();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const draftJson = sessionStorage.getItem(sessionStorageKey);
  const { getDefaultShipVia, isLoading: isLoadingShipVias } = useShipVias();

  const defaultOrderValue = useMemo(() => {
    const emptyOrder = buildEmptyOrder();
    const draftData = draftJson ? { ...JSON.parse(draftJson) } : null;
    const reshipOrder = location.state as SalesOrder;
    if (reshipOrder?.type) {
      return merge(emptyOrder, reshipOrder);
    }
    if (draftData) {
      return merge(
        emptyOrder,
        draftData.lines.length
          ? draftData
          : { ...draftData, lines: buildInittialLines() }
      );
    }
    return emptyOrder;
  }, []);

  const [draft, setDraft] = useState<any>(defaultOrderValue);
  const reactHookFormRet = useSalesOrderForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: defaultOrderValue,
    resolver: yupResolver(
      defaultOrderValue.type === 'Freight'
        ? createFreightSalesOrderSchema
        : createParcelSalesOrderSchema
    ),
  });
  const {
    handleSubmit,
    getValues,
    setValue,
    reset,
    formState: { isDirty },
  } = reactHookFormRet;

  useEffect(() => {
    if (
      isLoadingShipVias ||
      defaultOrderValue.type === 'Freight' ||
      Boolean(location.state)
    )
      return;
    setValue('shipVia', getDefaultShipVia());
  }, [isLoadingShipVias]);

  const onClose = () => {
    navigate(`/orders`);
  };

  const confirmAbort = useCallback(
    (callback: () => void) => {
      if (isDirty || draftJson) {
        sessionStorage.setItem(sessionStorageKey, JSON.stringify(getValues()));
      }

      callback();
    },
    [isDirty]
  );

  const { mutateAsync } = useCreateSalesOrder();

  const handleCreateOrder = useCrudActionUserFeedback<SalesOrder>({
    mutateAsync: () => mutateAsync(getValues()),
    actionType: 'CREATE',
    successMessage: 'Shipment successfully created',
    successCallback: () => {
      sendCreateSalesOrderEvent(draft.type, draft.soNumber);
      onClose();
      sessionStorage.removeItem(sessionStorageKey);
    },
    startCallback: () => {
      setIsSubmitting(true);
    },
    finallyCallback: () => {
      setIsSubmitting(false);
    },
    setFieldError: reactHookFormRet.setError,
    mapFieldDtoErrorToRhfPath,
  });

  const handleResetClick = () => {
    openConfirmationDialog({
      title: 'Discard changes',
      message: 'Discard changes?',
      onConfirm: () => {
        const draft = {
          ...buildEmptyOrder(),
          shipVia: getDefaultShipVia(),
        };
        setDraft(draft);
        reset(draft);
        sessionStorage.removeItem(sessionStorageKey);
      },
      blockScrollOnMount: false,
      confirmButtonLabel: 'Discard',
    });
  };

  const handleOrderTypeChange = (orderType: SalesOrderType) => {
    setDraft({ ...draft, type: orderType });
    setValue('shipVia', orderType === 'Freight' ? '' : getDefaultShipVia());
    setValue('type', orderType);
  };

  return (
    <FormProvider {...reactHookFormRet}>
      <Flex mb={6} justifyContent="space-between" gap={2}>
        <Text as="h2" variant="pageTitle">
          Create Shipment
        </Text>
        <Button
          px={4}
          py={2}
          fontWeight="normal"
          rightIcon={<RestoreIcon />}
          variant="ghost"
          onClick={handleResetClick}
        >
          Reset
        </Button>
      </Flex>
      <form onSubmit={handleSubmit(handleCreateOrder)}>
        <SimpleGrid columns={1} gap={8} mt={[5, null, 0]}>
          <GridItem>
            <Text variant="boldBlue" fontSize="xl">
              General
            </Text>
            <BasicInfoForm startTabIndex={0} />
          </GridItem>
          <GridItem>
            <Text variant="boldBlue" fontSize="xl">
              Ship To
            </Text>
            <ShipToForm startTabIndex={3} />
          </GridItem>
          <InstructionsForm tabIndex={13} />
          <GridItem>
            <Text variant="boldBlue" fontSize="xl">
              Shipping Options
            </Text>
            <Tabs
              mt={2}
              variant="unstyled"
              index={draft.type === 'Parcel' ? 0 : 1}
              isManual
              isLazy
            >
              <TabList color="#4A5568">
                {tabs.map((tab, index) => (
                  <Tab
                    key={index}
                    onClick={() => handleOrderTypeChange(tab.type)}
                    px={0}
                    py={2}
                    mr={6}
                    fontSize="xl"
                    _selected={{
                      color: 'primaryBlue.500',
                      borderBottomWidth: 2,
                      borderColor: 'primaryOrange.500',
                      fontWeight: 'bold',
                    }}
                    _hover={{ opacity: '0.75' }}
                  >
                    {tab.label}
                  </Tab>
                ))}
              </TabList>
            </Tabs>
            {draft.type === 'Parcel' && (
              <CreateParcelOrderForm startTabIndex={13} />
            )}
            {draft.type === 'Freight' && (
              <CreateFreightOrderForm startTabIndex={13} />
            )}
          </GridItem>
          <GridItem>
            <Text variant="boldBlue" fontSize="xl">
              SKUs
            </Text>
            <OrderLines
              startTabIndex={20}
              isFreightOrder={draft.type === 'Freight'}
            />
          </GridItem>
          <ButtonGroup
            wrapperProps={{
              display: 'grid',
              columnGap: 8,
              rowGap: 4,
              gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
            }}
            commonButtonWrapperProps={{ mr: 0, colSpan: 1 }}
            commonButtonProps={{ width: '100%' }}
            buttons={[
              {
                label: 'Create',
                variant: 'cta',
                isLoading: isSubmitting,
              },
              {
                label: 'Cancel',
                handler: () => confirmAbort(onClose),
              },
            ]}
          />
        </SimpleGrid>
      </form>
    </FormProvider>
  );
}
