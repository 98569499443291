import { useCallback, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { InventoryGrid } from 'features/inventory-list/InventoryGrid';
import {
  InventoryPageParams,
  queryToInventoryField,
  inventoryFieldToQuery,
} from 'features/inventory-list/inventoryPageParams';
import {
  getQueryParamsObject,
  parseFilters,
  parseSort,
  querifyFilters,
  querifySort,
  QueryParamsObject,
} from 'utils/urlSearchParamsUtils';
import { InventoryItem } from 'types/inventory';
import { SortState } from 'hooks/useGridSorting';
import { useStore } from 'contexts/storeContext';
import * as _ from 'lodash-es';
import { focusToField } from 'utils/browserUtils';

export function InventoryPage() {
  const [searchParams, setSearchParams] = useSearchParams({
    sortBy: '',
    page: '',
    pageSize: '',
  });
  const [state, dispatch] = useStore();

  const updateSearchParams = useCallback(
    (updating: object) =>
      setSearchParams({
        ...getQueryParamsObject(searchParams),
        page: '1',
        ...updating,
      }),
    [searchParams, setSearchParams]
  );

  const onFilterChange = useCallback(
    (filters: QueryParamsObject) => {
      dispatch(_.pick(filters, ['fieldToFocus']));
      updateSearchParams(
        querifyFilters(_.omit(filters, ['fieldToFocus']), inventoryFieldToQuery)
      );
    },
    [dispatch, updateSearchParams]
  );

  const onSortByChange = useCallback(
    (sorting: SortState<InventoryItem>) => {
      updateSearchParams({
        sortBy: querifySort(sorting, inventoryFieldToQuery),
      });
    },
    [updateSearchParams]
  );

  const params: InventoryPageParams = {
    initialSort: parseSort(
      searchParams.get('sortBy') || '',
      queryToInventoryField
    ),
    onSortByChange,
    initialPage: parseInt(searchParams.get('page') || '', 10),
    initialPageSize: parseInt(searchParams.get('pageSize') || '', 10),
    onPaginationChange: (pagination) => updateSearchParams(pagination),
    initialFilters: parseFilters(
      getQueryParamsObject(searchParams),
      queryToInventoryField
    ),
    onFilterChange,
  };

  useEffect(() => {
    if (state && state.fieldToFocus) {
      focusToField(state.fieldToFocus);
    }
  }, [state]);

  return <InventoryGrid {...params} />;
}
