import { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import get from 'lodash-es/get';

/* Components */
import { PaginatedTable } from 'components/PaginatedTable';
import { Link } from 'components/Link';

/* Types */
import { PurchaseOrder } from 'types/purchaseOrders';
import { Column } from 'react-table';
import {
  PurchaseOrderPageParams,
  dictFieldToQuery,
} from './purchaseOrderPageParams';

/* Utils */
import { useGridPagination } from 'hooks/useGridPagination';
import { usePurchaseOrders } from 'queries/purchaseOrders/usePurchaseOrders';
import { useWarehouses } from 'queries/warehouses/useWarehouses';
import { useGridSorting } from 'hooks/useGridSorting';
import { ColumnMetaData, useGridFiltering } from 'hooks/useGridFiltering';
import { formatDate } from 'utils/dateUtils';
import { CONFIG } from 'utils/config';
import { useDownloadReport } from 'utils/reportUtils';
import { REPORT_TYPES } from 'constants/reports';
import { useLayoutPaginatedTable } from 'hooks/useLayoutPaginatedTable';
import { BigLoading } from 'components/BigLoading';
import { MAX_WIDTH_MAIN_LAYOUT as MIN_WIDTH_STRETCH } from 'hoc/withLayout';
import { usePortal } from 'hooks/usePortal';
import { Box, Flex, Text } from '@chakra-ui/react';
import { ButtonGroup } from 'components/ButtonGroup';
import { getContainerNumbersString } from 'utils/purchaseOrdersUtils';
import { STATUS } from 'constants/purchaseOrderStatuses';

export interface PurchaseOrderGridProps extends PurchaseOrderPageParams {}

export function PurchaseOrderGrid(props: PurchaseOrderGridProps) {
  const { buildParameters, isDownloadingReport, onDownloadReport, resources } =
    useDownloadReport();
  const navigate = useNavigate();
  const location = useLocation();
  const { data: warehouses } = useWarehouses({
    refetchOnMount: false,
  });
  const availableStatus = [...STATUS];
  const initialStatus = get(props.initialFilters, 'status');
  const buttons = [
    {
      label: resources.labels.button,
      handler: () => {
        onDownloadReport(
          REPORT_TYPES.PURCHASE_ORDERS,
          buildParameters(dictFieldToQuery, filters)
        );
      },
      isLoading: isDownloadingReport,
      tooltip: isDownloadingReport
        ? resources.labels.tooltip.isDownloading
        : resources.labels.tooltip.idle,
    },
  ];
  const { isPortalReadOnly } = usePortal();

  const gridPagination = useGridPagination({
    initialPage: props.initialPage,
    initialPageSize: props.initialPageSize,
    onPaginationChange: props.onPaginationChange,
  });

  const gridSorting = useGridSorting<PurchaseOrder>({
    getQueryState: () => queryState,
    initialSort: props.initialSort,
    onSortChange: props.onSortByChange,
  });

  const columnsMetaData: {
    [key in keyof Partial<PurchaseOrder>]: ColumnMetaData;
  } = {
    poNumber: { dataType: 'text' },
    status: {
      dataType: 'select',
      selectOptions: availableStatus as Array<string>,
    },
    createdDate: { dataType: 'date-range' },
    requestedDate: { dataType: 'date-range' },
    warehouseID: {
      dataType: 'select',
      selectOptions:
        warehouses?.map((warehouse) => ({
          label: warehouse.displayText,
          value: warehouse.code,
        })) ?? [],
    },
    totalOrderQty: { dataType: 'number' },
    totalReceivedQty: { dataType: 'number' },
    totalLines: { dataType: 'number' },
    vendorName: { dataType: 'text' },
    containerNumbers: { dataType: 'text' },
  };

  const gridFiltering = useGridFiltering<PurchaseOrder>({
    columnsMetaData,
    initialFilters: props.initialFilters,
    onFilterChange: props.onFilterChange,
  });

  const filters = {
    ...gridFiltering.filters,
    status: initialStatus ? initialStatus.split(',') : availableStatus,
  };

  const queryState = usePurchaseOrders(
    { skip: gridPagination.skip, limit: gridPagination.limit },
    filters,
    gridSorting.sort
  );

  const { widthWindow, getColumnWidth } =
    useLayoutPaginatedTable(MIN_WIDTH_STRETCH);

  const purchaseOrderColumns = useMemo<Column<PurchaseOrder>[]>(
    () => [
      {
        Header: 'Shipping Plan Number',
        accessor: 'poNumber',
        width: getColumnWidth(15, 200),
        Cell: ({ value: poNumber }: { value: string }) => (
          <Link
            to={`/inbound-inventory/${encodeURIComponent(poNumber)}${
              location.search
            }`}
            fontWeight="bold"
            _hover={{ textDecoration: 'underline' }}
          >
            {poNumber}
          </Link>
        ),
      },
      {
        Header: 'Status',
        accessor: 'status',
        width: getColumnWidth(10, 150),
      },
      {
        Header: 'Container Numbers',
        accessor: 'containerNumbers',
        width: getColumnWidth(17, 230),
        Cell: ({ value }: { value: { containerNumber: string }[] }) => (
          <>{getContainerNumbersString(value)}</>
        ),
      },
      {
        Header: 'Created Date',
        accessor: 'createdDate',
        width: getColumnWidth(12.1, 180),
        Cell: ({ value }: { value: string }) => <>{formatDate(value)}</>,
        getValue: formatDate,
      },
      {
        Header: 'Requested Date',
        accessor: 'requestedDate',
        width: getColumnWidth(13.5, 200),
        Cell: ({ value }: { value: string }) => (
          <>{formatDate(value, 'MM/DD/YYYY', { timeZone: 'UTC' })}</>
        ),
        getValue: formatDate,
      },
      {
        Header: 'Fulfillment Center',
        accessor: 'warehouseID',
        Cell: ({ value }) => {
          const warehouseInfo = warehouses?.find((item) => item.code === value);
          return <>{warehouseInfo?.displayText || value}</>;
        },
        width: getColumnWidth(13.5, 200),
      },
      {
        Header: 'Order Qty',
        accessor: 'totalOrderQty',
        isNumeric: true,
        width: getColumnWidth(12.1, 170),
        helpText: 'Total number of items included in the order',
      },
      {
        Header: 'Received Qty',
        accessor: 'totalReceivedQty',
        isNumeric: true,
        width: getColumnWidth(13, 170),
        helpText: 'Total received quantity across all lines',
      },
      {
        Header: 'Lines',
        accessor: 'totalLines',
        isNumeric: true,
        width: getColumnWidth(12.1, 170),
        helpText: 'Total number of lines in the order',
      },
    ],
    [location.search, getColumnWidth, warehouses]
  );

  if (
    CONFIG().featureToggles.enableSchedulePurchaseOrder &&
    !isPortalReadOnly
  ) {
    buttons.push({
      label: 'Create Shipping Plan',
      variant: 'cta',
      handler: () => {
        navigate('/inbound-inventory/schedule');
      },
    } as any);
  }

  return widthWindow ? (
    <Box>
      <Flex
        justifyContent="space-between"
        alignItems={[null, 'center']}
        flexDirection={['column', null, 'row']}
      >
        <Text mb={[buttons.length ? 4 : 0, 0]} as="h2" variant="pageTitle">
          Inbound Shipping Plans
        </Text>
        {buttons.length ? (
          <ButtonGroup
            buttons={buttons}
            wrapperProps={{
              display: 'flex',
              flexDirection: ['column-reverse', null, 'row'],
              width: 'auto',
            }}
            commonButtonProps={{ fontSize: 'lg' }}
            commonButtonWrapperProps={{ mr: 0 }}
          />
        ) : null}
      </Flex>
      <PaginatedTable
        columns={purchaseOrderColumns}
        gridPagination={gridPagination}
        gridSorting={gridSorting}
        gridFiltering={gridFiltering}
        queryState={queryState}
        tableName="purchase_orders"
        isResizable
      />
    </Box>
  ) : (
    <BigLoading />
  );
}
