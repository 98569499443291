import { PaginatedTable } from 'components/PaginatedTable';
import { useMemo } from 'react';
import { Column } from 'react-table';
import { NetworkOrderLineItemDetails } from 'types/networkOrder';
import { QueryState } from 'types/queryState';

type Props = {
  orderQty: number;
  data: NetworkOrderLineItemDetails[];
};

function NetworkOrderLineDetailsTable({ orderQty, data }: Props) {
  const columns = useMemo(
    () =>
      [
        {
          accessor: 'lineNumber',
          width: 82,
        },
        {
          accessor: 'sku',
          width: 298,
        },
        {
          accessor: 'qtyRequested',
          width: 250,
          Cell: ({
            value,
            row: {
              original: { qtyPerParent },
            },
          }) =>
            orderQty > 1 ? (
              <>
                {qtyPerParent} per bundle x {orderQty} bundle(s) = {value}
              </>
            ) : (
              value
            ),
        },
      ] as Column<NetworkOrderLineItemDetails>[],
    [orderQty]
  );

  return (
    <PaginatedTable
      mt={-2}
      mb={0}
      pl={5}
      hideHeader
      columns={columns}
      queryState={
        {
          data,
          isLoading: !data,
        } as QueryState<NetworkOrderLineItemDetails>
      }
      tableName="network_order_line_item_details_table"
      isResizable
    />
  );
}

export default NetworkOrderLineDetailsTable;
