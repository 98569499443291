import { NetworkOrderLineItem, NetworkOrderShipment } from 'types/networkOrder';

export function lineItemHasBundle(lineItem: NetworkOrderLineItem) {
  return !!lineItem.childSkus;
}

export function getBundlesFromShipment(
  shipment: NetworkOrderShipment,
  orderLineItems: NetworkOrderLineItem[]
) {
  return orderLineItems
    .filter(lineItemHasBundle)
    .filter((lineItem) =>
      lineItem.childSkus?.some((childSku) =>
        shipment.lineItems.some(
          (item) => item.lineNumber === childSku.lineNumber
        )
      )
    );
}
