import { PurchaseOrder } from 'types/purchaseOrders';

export function isChangeable(order: PurchaseOrder) {
  return ['Created'].includes(order.status);
}

export function parseContainerStatus(containerStatus: string) {
  return containerStatus !== 'OPEN' ? 'Checked-in' : 'Pending';
}

export function getContainerNumbersString(
  containerNumbers: { containerNumber: string }[],
  containerStatus: string[] = [],
  delimiter: string = ', '
) {
  return (
    containerNumbers?.reduce(
      (res, { containerNumber }, index) =>
        `${res === '' ? res : `${res}${delimiter}`} ${containerNumber}${
          containerStatus[index]
            ? ` - ${parseContainerStatus(containerStatus[index])}`
            : ''
        }`,
      ''
    ) ?? ''
  );
}
