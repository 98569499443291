import { AddressInfo, AddressInfoDto } from './address';
import { PaginatedResponse } from './paginatedResponse';
import { StatusHistoryEntry } from './status';
import { Storefront, StorefrontDto } from './tenant';

export type ParcelSalesOrderType = 'Parcel';
export type SalesOrderType = ParcelSalesOrderType | 'Freight';
export enum SalesOrderStatus {
  Received = 'Received',
  ReceivedUpdated = 'Received-Updated',
  PickingStarted = 'Picking-Started',
  Picked = 'Picked',
  Labelled = 'Labelled',
  CarrierError = 'Carrier-Error',
  Loaded = 'Loaded',
  Shipped = 'Shipped',
  PickedShort = 'Picked-Short',
  LoadedShort = 'Loaded-Short',
  ShippedPartial = 'Shipped-Partial',
  ShippedShort = 'Shipped-Short',
  Cancelled = 'Cancelled',
  CancellationRequested = 'Cancellation-Requested',
}

export type SalesOrderLineBase = {
  lineNo: string;
  customerItemNumber: string;
  sku: string;
  bundleSku?: string;
  unitOfMeasure: string;
  description: string;
  shipDate: string;
  orderQty: number;
  pickedQty: number;
  shippedQty: number;
  shortQty: number;
  trackingNumbers: string[];
  unpickedQty: number;
  canceledQty: number;
};

export type SalesOrderTrackingNumbersDetail = {
  soLineNumber: number;
  trackingNumbers: string[];
  customerShipmentReference: string | null;
  shipmentDetails?: {
    trackingNumber: string;
    carrier: string;
    service: string;
  }[];
};

export type ShippingBoxItem = {
  sku: string;
  qty: number;
};

export type SalesOrderShippingBoxesDetail = {
  trackingNumber: string;
  packageNumber: string;
  items: ShippingBoxItem[];
};

export type ParcelSalesOrderLine = SalesOrderLineBase & {};

export type FreightSalesOrderLine = SalesOrderLineBase & {
  customerShipmentReference?: string;
  arn?: string;
  asn?: string;
};

export type SalesOrderCommon = {
  soNumber: string;
  poNumber: string;
  customerNumber: string;
  customerName: string;
  createdDate: string;
  dueDate?: string;
  earliestDueDate?: string;
  latestDueDate: string;
  shipDate: string;
  shipVia: string;
  shipFrom: Omit<AddressInfo, 'address2' | 'country' | 'email' | 'phoneNumber'>;
  shipTo: AddressInfo;
  totalLines: number;
  totalOrderQty: number;
  totalPickedQty: number;
  totalShippedQty: number;
  totalShortQty: number;
  totalUnpickedQty: number;
  totalCanceledQty: number;
  status: SalesOrderStatus;
  statusDate: string;
  instructions: string | null;
  warehouseID: string;
  statusChangesHistory: StatusHistoryEntry<SalesOrderStatus>[];
  carrierScacCode: string;
  carrierAccountUUID: string;
  targetDc: string;
  trackingNumbers: string[];
  trackingNumbersDetail?: SalesOrderTrackingNumbersDetail[];
  giftMessage: string;
  shippingBoxesDetail?: SalesOrderShippingBoxesDetail[];
  storefrontName?: string;
  storefront?: Storefront;
};

export type SalesOrderSearchResult = {
  soNumber: string;
};

export type SalesOrderSummary = {
  totalPending: number;
  totalReceived: number;
  totalStarted: number;
  totalWaiting: number;
  totalExceptions: number;
};

export type SalesOrderClosedSummary = {
  totalShipped: number;
  totalShippedPartial: number;
  totalShippedShort: number;
};

export type ParcelSalesOrder = SalesOrderCommon & {
  type: ParcelSalesOrderType;
  shipOption?: string;
  lines: ParcelSalesOrderLine[];
};

export type BolData = {
  type: string;
  bolNumber: string;
  shipToCidNumber: string;
  shipToLocationNumber: string;
  fob: boolean;
  billingAddress: AddressInfo | null;
};

export type FreightSalesOrder = SalesOrderCommon & {
  type: 'Freight';
  lines: FreightSalesOrderLine[];
  bolData: BolData | null;
  arn?: string;
  asn?: string;
  printPackingLabels?: boolean;
  printGtin14Label?: boolean;
  printUpcLabel?: boolean;
};

export type SalesOrder = ParcelSalesOrder | FreightSalesOrder;

export type SalesOrderLineBaseDto = {
  line_number: string;
  external_item_number: string;
  parent_bundle_sku: string | null;
  sku: string;
  item_description: string;
  latest_ship_date: string;
  uom: string;
  order_qty: number;
  picked_qty: number;
  ship_qty: number;
  short_qty: number;
  tracking_numbers: string[];
  unpicked_qty: number;
  canceled_qty: number;
};

export type ParcelSalesOrderLineDto = SalesOrderLineBaseDto & {};

export type FreightSalesOrderLineDto = SalesOrderLineBaseDto & {
  customer_shipment_reference?: string;
  arn?: string;
  asn?: string;
};

/**
 * This type contains the shared properties between all Sales Order DTOs
 */
type SalesOrderDtoCommon = {
  so_number: string;
  po_number: string;
  target_dc: string;
  ship_via: string;
  ship_from_address: Omit<
    AddressInfoDto,
    'address_2' | 'country' | 'email' | 'phone_number'
  >;
  ship_to_address: AddressInfoDto;
  customer_order_reference: string;
  instructions: string | null;
  created_date: string;
  due_date?: string;
  earliest_due_date?: string;
  latest_ship_date: string;
  carrier_account_uuid?: string | null;
  carrier_scac_code?: string | null;
  latest_status: string;
  tracking_numbers: string[];
  tracking_numbers_detail?: {
    so_line_number: number;
    tracking_numbers: string[];
    customer_shipment_reference: string | null;
    shipment_details:
      | {
          tracking_number: string;
          carrier: string;
          service: string;
        }[]
      | undefined;
  }[];
  gift_message: string;
  shipping_boxes_detail?: {
    tracking_number: string;
    package_number: string;
    items: {
      sku: string;
      qty: number;
    }[];
  }[];
  storefront?: StorefrontDto;
  storefront_name?: string;
};

/**
 * This type contains the shared properties between the two
 * different Sales Order Condensed DTOs (Freight and Parcel).
 * Sales Order Condensed DTOs are the ones returned by the
 * GET-collection endpoints, such as:
 * GET /salesorder
 * GET /salesorder/freight
 * GET /salesorder/parcel
 */
type SalesOrderCondensedDtoCommon = {
  status_date: string;
  status_error_msg: string;
  total_lines: number;
  total_order_qty: number;
  total_picked_qty: number;
  total_shipped_qty: number;
  total_short_qty: number;
  total_unpicked_qty: number;
  total_canceled_qty: number;
};

/**
 * This type contains the shared properties between the two
 * different Sales Order Detail DTOs (Freight and Parcel).
 * Sales Order Detail DTOs are the ones returned by the
 * GET-by-so_number endpoints, such as:
 * GET /salesorder/:so_number
 * GET /salesorder/freight/:so_number
 * GET /salesorder/parcel/:so_number
 */
type SalesOrderDetailDtoCommon = {
  sales_order_lines: ParcelSalesOrderLineDto[] | FreightSalesOrderLineDto[];
  status_history: {
    status: string;
    status_date: string;
    status_error_message: string;
  }[];
};

/**
 * This type contains the shared properties between the two
 * different Parcel Sales Order DTOs (Detail and Condensed).
 */
type ParcelSalesOrderDtoCommon = SalesOrderDtoCommon & {
  processing_type: 'Parcel';
  ship_option?: string;
  carrier_account_uuid: string;
};

/**
 * This type contains the shared properties between the two
 * different Freight Sales Order DTOs (Detail and Condensed).
 */
type FreightSalesOrderDtoCommon = SalesOrderDtoCommon & {
  processing_type: 'Freight';
};

/**
 * A Parcel Sales Order Detail DTO, as returned by GET-by-so_number endpoints.
 */
export type ParcelSalesOrderDetailDto = ParcelSalesOrderDtoCommon &
  SalesOrderDetailDtoCommon;

/**
 * A Freight Sales Order Detail DTO, as returned by GET-by-so_number endpoints.
 */
export type FreightSalesOrderDetailDto = FreightSalesOrderDtoCommon &
  SalesOrderDetailDtoCommon & {
    sales_order_lines: FreightSalesOrderLineDto[];
    arn: string;
    asn: string;
    print_packing_labels: boolean;
    print_gtin14_label: boolean;
    print_upc_label: boolean;
    bol_data: {
      bol_type: string;
      bol_number: string;
      ship_to_cid_number: string;
      ship_to_location_number: string;
      fob: boolean;
      bill_to_address: {
        name: string;
        address: string;
        address_2: string;
        city: string;
        state: string;
        zip_code: string;
        country: string;
        email: string;
        phone: string;
      } | null;
    } | null;
  };

/**
 * A Parcel Sales Order Condensed DTO, as returned by GET-collection endpoints.
 */
export type ParcelSalesOrderCondensedDto = ParcelSalesOrderDtoCommon &
  SalesOrderCondensedDtoCommon & {
    sales_order_lines: ParcelSalesOrderLineDto[];
  };

/**
 * A Freight Sales Order Condensed DTO, as returned by GET-collection endpoints.
 */
export type FreightSalesOrderCondensedDto = FreightSalesOrderDtoCommon &
  SalesOrderCondensedDtoCommon;

/**
 * A Sales Order Detail DTO, as returned by GET-by-so_number endpoints.
 */
export type SalesOrderDetailDto =
  | FreightSalesOrderDetailDto
  | ParcelSalesOrderDetailDto;

/**
 * A Sales Order Condensed DTO, as returned by GET-collection endpoints.
 */
export type SalesOrderCondensedDto =
  | FreightSalesOrderCondensedDto
  | ParcelSalesOrderCondensedDto;

export type SalesOrderDto = SalesOrderDetailDto | SalesOrderCondensedDto;

export type SalesOrderSearchResultDto = {
  so_number: string;
};

/**
 * A Sales Order Summary DTO, as returned by GET-TBD endpoint.
 */
export type SalesOrderSummaryDto = {
  total_pending: number;
  total_received: number;
  total_started: number;
  total_waiting: number;
  total_exceptions: number;
};

export type SalesOrderClosedSummaryDto = {
  counts: {
    count: number;
    status: SalesOrderStatus;
  }[];
};

export type PaginatedOrdersResponseDto = PaginatedResponse & {
  items: SalesOrderCondensedDto[];
};

export type CancelSalesOrderResult = {
  soNumber: string;
  orderStatus?: SalesOrderStatus;
  messages?: string[];
};

export type CancelSalesOrderErrorResultDto = {
  order_status?: SalesOrderStatus;
  error?: string[];
};

export type FreightLabelDetailDto = {
  sscc_code: string;
  sku: string;
  upc: string;
  item_description: string;
  qty: number;
  carton_num: number;
};

export type FreightLabelInfoDto = {
  po_number: string;
  from_company: string;
  from_address_line1: string;
  from_address_line2: string;
  ship_from_city_state_zip: string;
  to_company: string;
  to_address_line1: string;
  to_address_line2: string;
  ship_to_city_state_zip: string;
  ship_to_postal_code: string;
  scac_code: string;
  pro_number: string;
  bol_number: string;
  total_carton_num: number;
  label_detail_list: FreightLabelDetailDto[];
};

export type FreightLabelDetail = {
  ssccCode: string;
  sku: string;
  upc: string;
  itemDescription: string;
  qty: number;
  cartonNum: number;
};

export type FreightLabelInfo = {
  poNumber: string;
  fromCompany: string;
  fromAddressLine1: string;
  fromAddressLine2: string;
  shipFromCityStateZip: string;
  toCompany: string;
  toAddressLine1: string;
  toAddressLine2: string;
  shipToCityStateZip: string;
  shipToPostalCode: string;
  scacCode: string;
  proNumber: string;
  bolNumber: string;
  totalCartonNum: number;
  labelDetails: FreightLabelDetail[];
};
