import { Box, ListItem, Text, UnorderedList } from '@chakra-ui/react';
import { InlineText } from 'components/InlineText';
import { Modal } from 'components/Modal';
import { usePurchaseOrderContact } from 'hooks/usePurchaseOrderContact';
import { PurchaseOrder } from 'types/purchaseOrders';
import { formatDate } from 'utils/dateUtils';

type Props = {
  data: PurchaseOrder;
  onClose: () => void;
};

export function SuccessSchedulePurchaseOrderModal({ data, onClose }: Props) {
  const { copy, url } = usePurchaseOrderContact([data.poNumber]);

  return (
    <Modal
      title={`Shipping Plan ${data.poNumber}`}
      isOpen={true}
      size={['full', null, '7xl']}
      onClose={onClose}
      buttons={[
        {
          label: 'Contact',
          variant: 'cta',
          handler: () => window.open(url, '_blank'),
        },
        {
          label: 'Copy',
          handler: copy,
        },
        {
          label: 'Done',
          handler: onClose,
        },
      ]}
    >
      <Box>
        <Text as="h3" variant="pageHeading" mb={2}>
          Successfully Created
        </Text>
        <UnorderedList ml={0} mb={8} listStyleType="none" fontWeight={300}>
          <ListItem>Lines: {data.lines.length}</ListItem>
          <ListItem>
            Total Qty:{' '}
            {data.lines.reduce((acc, line) => acc + line.orderQty, 0)}
          </ListItem>
          <ListItem>Destination: {data.warehouseID}</ListItem>
          <ListItem>
            Requested Delivery Date: {formatDate(data.requestedDate)}
          </ListItem>
        </UnorderedList>
        <Text as="h3" variant="pageHeading" mb={2}>
          Schedule Inbound Delivery
        </Text>
        <Text mb={2}>
          Next step is to contact the Standvast transportation team{' '}
          <InlineText fontWeight={500}>at least 2 business days</InlineText>
          <br />
          <InlineText fontWeight={500}>
            before {formatDate(data.requestedDate)}
          </InlineText>{' '}
          (the requested delivery date) to coordinate arrival details.
        </Text>
        <Text>
          Contact us yourself or copy this link and share it with your carrier
        </Text>
      </Box>
    </Modal>
  );
}
