import { AxiosError } from 'axios';
import { BigLoading } from 'components/BigLoading';
import { ErrorBoundary } from 'components/ErrorBoundary';
import { ExceptionState } from 'components/ExceptionState';
import { Modal } from 'components/Modal';
import { CancelOrderPrompt } from 'features/order-details/components/CancelOrder/CancelOrderPrompt';
import { useSalesOrder } from 'queries/salesOrders';
import { useNavigate, useParams } from 'react-router-dom';

export function CancelOrderPage() {
  const { salesOrderNumber } = useParams();
  const navigate = useNavigate();
  const { data: order, error } = useSalesOrder(salesOrderNumber);

  const handleAbort = () => navigate(-1);

  const handleConfirm = () => navigate('/orders');

  return (
    <Modal
      isOpen={true}
      onClose={handleAbort}
      size={['full', null, '2xl']}
      modalBodyProps={{
        display: ['flex', null, 'block'],
      }}
    >
      <ErrorBoundary>
        {(error as AxiosError)?.response?.status === 404 ? (
          <ExceptionState customTitle="Shipment Not Found" stateType="empty" />
        ) : order ? (
          <CancelOrderPrompt
            order={order}
            onAbort={handleAbort}
            onConfirm={handleConfirm}
          />
        ) : (
          <BigLoading />
        )}
      </ErrorBoundary>
    </Modal>
  );
}
