import { AxiosError } from 'axios';
import { BigLoading } from 'components/BigLoading';
import { ExceptionState } from 'components/ExceptionState';
import { ModifyOrder } from 'features/order-details/components/ModifyOrder/ModifyOrder';
import { useSalesOrder } from 'queries/salesOrders';
import { useNavigate, useParams } from 'react-router-dom';

export function ModifyOrderPage() {
  const { salesOrderNumber } = useParams();
  const navigate = useNavigate();
  const { data: order, error } = useSalesOrder(salesOrderNumber);

  const handleClose = () => {
    navigate(`/orders/${salesOrderNumber}`);
  };

  if ((error as AxiosError)?.response?.status === 404) {
    return (
      <ExceptionState customTitle="Shipment Not Found" stateType="empty" />
    );
  }

  return order ? (
    <ModifyOrder order={order} onClose={handleClose} />
  ) : (
    <BigLoading />
  );
}
