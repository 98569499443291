import { AxiosError } from 'axios';
import { BigLoading } from 'components/BigLoading';
import { ExceptionState } from 'components/ExceptionState';
import { ModifyPurchaseOrder } from 'features/purchase-order-details/components/ModifyPurchaseOrder/ModifyPurchaseOrder';
import { usePurchaseOrder } from 'queries/purchaseOrders/usePurchaseOrder';
import { useNavigate, useParams } from 'react-router-dom';

export function ModifyPurchaseOrderPage() {
  const { purchaseOrderNumber } = useParams();
  const navigate = useNavigate();
  const { data: order, error } = usePurchaseOrder(purchaseOrderNumber!);

  const handleClose = () => {
    navigate(`/inbound-inventory/${purchaseOrderNumber}`);
  };

  if ((error as AxiosError)?.response?.status === 404) {
    return (
      <ExceptionState customTitle="Shipping Plan Not Found" stateType="empty" />
    );
  }

  return order ? (
    <ModifyPurchaseOrder order={order} onClose={handleClose} />
  ) : (
    <BigLoading />
  );
}
