import { useEffect } from 'react';
import { Text } from '@chakra-ui/react';
import { useInventoryItems } from 'queries/inventory/useInventoryItems';
import { useItemMaster } from 'queries/itemMasters/useItemMaster';
import { Shimmer } from 'components/Shimmer';
import { ExceptionState } from 'components/ExceptionState';
import { AxiosError } from 'axios';
import { ErrorBoundary } from 'components/ErrorBoundary';
import { sendViewInventoryItemDetailsEvent } from 'utils/monitorEvents';
import { useParams } from 'react-router-dom';
import { Measurements } from 'features/inventory-item-details/Measurements';
import { Quantities } from 'features/inventory-item-details/Quantities';

export function InventoryItemDetailsPage() {
  const { sku } = useParams();
  const {
    data: inventoryItems,
    isFetching: isFetchingInventoryItems,
    error: inventoryError,
  } = useInventoryItems(sku!);

  const {
    data: itemMaster,
    isFetching: isFetchingItemMaster,
    error: itemMasterError,
  } = useItemMaster(sku!);

  useEffect(() => sendViewInventoryItemDetailsEvent(sku ?? ''), [sku]);

  const skuNotFound =
    (inventoryError as AxiosError)?.response?.status === 404 ||
    (itemMasterError as AxiosError)?.response?.status === 404;
  const isLoading =
    !itemMaster ||
    !inventoryItems ||
    isFetchingItemMaster ||
    isFetchingInventoryItems;

  if (skuNotFound) {
    return <ExceptionState customTitle="Item Not Found" stateType="empty" />;
  }

  return (
    <>
      <Text mb={6} as="h2" variant="pageTitle">
        SKU {sku}
      </Text>
      <Shimmer w="300px" h="40px" isLoaded={!isLoading}>
        <Text as="h2" fontSize="xl">
          {itemMaster?.description}
        </Text>
      </Shimmer>
      <ErrorBoundary size="compact">
        <Measurements itemMaster={isLoading ? undefined : itemMaster} />
      </ErrorBoundary>
      <ErrorBoundary size="compact">
        <Quantities inventoryItems={isLoading ? undefined : inventoryItems} />
      </ErrorBoundary>
    </>
  );
}
