import { Box } from '@chakra-ui/react';
import { PaginatedTable } from 'components/PaginatedTable';
import { useLayoutPaginatedTable } from 'hooks/useLayoutPaginatedTable';
import { useMemo } from 'react';
import { Column } from 'react-table';
import { QueryState } from 'types/queryState';
import { CancelSalesOrderResult } from 'types/salesOrders';

type Props = {
  data: CancelSalesOrderResult[];
};

export function BulkCancelTable({ data }: Props) {
  const { getColumnWidth } = useLayoutPaginatedTable(0);

  const columns = useMemo(() => {
    return [
      {
        Header: 'Shipment #',
        accessor: 'soNumber',
        width: getColumnWidth(15, 150),
      },
      {
        Header: 'Status',
        accessor: 'orderStatus',
        width: getColumnWidth(15, 170),
      },
      {
        Header: 'Message',
        accessor: 'messages',
        width: getColumnWidth(55, 400),
        Cell: ({ value }: { value?: string[] }) => (
          <Box whiteSpace="pre-line">{value?.join('\n')}</Box>
        ),
      },
    ] as Column<CancelSalesOrderResult>[];
  }, [getColumnWidth]);

  return (
    <PaginatedTable
      clientSide
      tableName="bulk_cancel_sales_orders_table"
      columns={columns}
      queryState={
        {
          data,
          isLoading: false,
        } as QueryState<CancelSalesOrderResult>
      }
      isResizable
    />
  );
}
