import { GridItem } from '@chakra-ui/react';
import {
  FormSelectMulti,
  FormSelectMultiOnChangeEvent,
  OptionSelect,
} from 'components/FormSelectMulti';
import { AUTH0_ROLES } from 'constants/auth0Roles';
import { useAuth0Roles } from 'hooks/useAuth0Roles';
import { useConfirmationDialog } from 'hooks/useConfirmationDialog';
import { useRoles } from 'queries/role/useRoles';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { UserProfileModify } from 'types/userProfile';
import { isStandvastEmail } from 'utils/regexpUtils';

type Props = {
  email?: string;
};

export function UserProfileRole({ email }: Props) {
  const { data: dataRolesOption } = useRoles();
  const { openConfirmationDialog } = useConfirmationDialog();
  const isAdmin = useAuth0Roles([AUTH0_ROLES.STANDVAST_ADMIN]);
  const { register, setValue } = useFormContext<UserProfileModify>();

  const rolesOption = useMemo(() => {
    if (dataRolesOption?.length) {
      let newRoles: OptionSelect[] = dataRolesOption.map((role) => ({
        label: role.name,
        value: role.id,
      }));
      return !isAdmin || !isStandvastEmail(email || '')
        ? newRoles.filter(
            (role) =>
              role.label.toLowerCase() !==
              AUTH0_ROLES.STANDVAST_ADMIN.toLowerCase()
          )
        : newRoles;
    }
    return [];
  }, [dataRolesOption, isAdmin, email]);

  const changeUserRole = (name: 'roles', newValue: OptionSelect[]) => {
    setValue(name, newValue, {
      shouldDirty: true,
      shouldValidate: true,
    });
  };

  const checkForReadOnlyRoleInRoles = (roles: OptionSelect[]) => {
    return roles.some(
      (role) =>
        role.label.toLowerCase() === AUTH0_ROLES.PORTAL_READ_ONLY.toLowerCase()
    );
  };

  const handleChangeUserRole = ({
    target: { currentValue, name, value },
  }: FormSelectMultiOnChangeEvent) => {
    const userHasReadOnlyRole = checkForReadOnlyRoleInRoles(currentValue);

    if (userHasReadOnlyRole) {
      const isRemovingReadOnlyRole = !checkForReadOnlyRoleInRoles(value);
      if (isRemovingReadOnlyRole) {
        changeUserRole(name as 'roles', value);
      } else {
        openConfirmationDialog({
          title: 'Changing user role',
          message:
            'Read Only users cannot have multiple roles. Completing this action will remove the Read Only role. Click "Confirm - Remove" to complete this change. Click on "Cancel" to cancel this change request.',
          onConfirm: () =>
            changeUserRole(
              name as 'roles',
              value.filter(
                (val) =>
                  val.label.toLowerCase() !==
                  AUTH0_ROLES.PORTAL_READ_ONLY.toLowerCase()
              )
            ),
          blockScrollOnMount: true,
          confirmButtonLabel: 'Confirm - Remove',
        });
      }
    } else {
      const isAddingReadOnlyRoleToUser = checkForReadOnlyRoleInRoles(value);
      const hasOtherRoles = currentValue.length > 0;
      if (isAddingReadOnlyRoleToUser && hasOtherRoles) {
        openConfirmationDialog({
          title: 'Changing user role',
          message:
            'Read Only users cannot have multiple roles. Any non-Read Only roles assigned to this user will be automatically unassigned once this action is taken. Click on "Confirm - Remove" to complete this change. Click on "Cancel" to cancel this change request.',
          onConfirm: () =>
            changeUserRole(
              name as 'roles',
              value.filter(
                (val) =>
                  val.label.toLowerCase() ===
                  AUTH0_ROLES.PORTAL_READ_ONLY.toLowerCase()
              )
            ),
          blockScrollOnMount: true,
          confirmButtonLabel: 'Confirm - Remove',
        });
      } else {
        changeUserRole(name as 'roles', value);
      }
    }
  };

  return (
    <GridItem>
      <FormSelectMulti
        {...register('roles')}
        placeholder="Please select a role"
        label="Select Role"
        hideLabel={true}
        options={rolesOption}
        onChange={handleChangeUserRole}
      />
    </GridItem>
  );
}
