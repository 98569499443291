export const SALES_ORDER_URL =
  'https://docs.google.com/spreadsheets/d/1xvs3HWOXHkUAVO4TIKcbJcuSCwsc1tGaOd4w6Jgm76g/edit?usp=sharing';
export const PURCHASE_ORDER_URL =
  'https://docs.google.com/spreadsheets/d/1W51V596Xed44KrbvlqBRYBspILVQH80UCsghjIyy7po/edit?usp=sharing';
export const FORECAST_URL =
  'https://docs.google.com/spreadsheets/d/14pm_laOi2HEYZJvRWVJzpbJhBKkDKF0IuWcbqg77fPI/edit?gid=1691338767#gid=1691338767';
export const SHIPPING_ACCOUNT_SETUP_URL =
  'https://docs.google.com/spreadsheets/d/1oEjRiNX2T4TxcXOkT-hftOIiP00YEezdxpScgziwpaA/edit?usp=sharing';
export const CREATE_ITEM_MASTER_URL =
  'https://docs.google.com/spreadsheets/d/1sjRQS0XhuC2oWKtdFiq-xDtuq1xV_IHbbIIztTYIYiw/edit?usp=sharing';
export const UPDATE_ITEM_MASTER_URL =
  'https://docs.google.com/spreadsheets/d/1S06eTN2Ic_wKpmrUChe6-TWPv-f0tOjLwa7zhKZL1Kw/edit?usp=sharing';
export const SUBMIT_TEMPLATE_URL =
  'https://standvast-team.atlassian.net/servicedesk/customer/portal/6';
export const BARNES_AND_NOBLES =
  'https://docs.google.com/spreadsheets/d/164Mt-hZRvp3bAQjWxBiS4KB9J-X1ytVhUoj1U_4zl74/edit?usp=sharing';
export const SHORELIGHT =
  'https://docs.google.com/spreadsheets/d/1c62CZ2SDMHPuqlrte9pG49AEEUV5dEOYoa5pOltkbMg/edit?usp=sharing';
export const WALMART_ORDER_URL =
  'https://docs.google.com/spreadsheets/d/157EJkqUo1YayGl5wS9vYUyFMRBCePvwxig-yrXc2Iac/edit?usp=sharing';
export const WHOLESALE_ORDER_URL =
  'https://docs.google.com/spreadsheets/d/14kEKsr4VR9M52YZDBY9pcR5RLMfcHtqvJ2v_R4GfLOs/edit?usp=sharing';
