import { useNavigate, useParams, useLocation, Outlet } from 'react-router-dom';
import { useSalesOrder } from 'queries/salesOrders';
import { isReshipOrder, removeReshipSuffix } from 'utils/salesOrdersUtils';
import { OrderDetails } from 'features/order-details/OrderDetails';
import { ExceptionState } from 'components/ExceptionState';
import { AxiosError } from 'axios';

export function OrderDetailsPage() {
  const { salesOrderNumber } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const urlSegments = location.pathname.split('/');
  const firstUrlSegment = urlSegments[1] ?? '';

  const { data: order, isFetching, error } = useSalesOrder(salesOrderNumber);
  const { data: parentOrder, isFetching: isFetchingParentOrder } =
    useSalesOrder(
      isReshipOrder(salesOrderNumber!)
        ? removeReshipSuffix(salesOrderNumber!)
        : null
    );

  const handleEdit = () => {
    navigate(`/${firstUrlSegment}/${salesOrderNumber}/edit`);
  };

  const handleCancel = () => {
    navigate(`/${firstUrlSegment}/${salesOrderNumber}/cancel`);
  };

  const handleReRoute = () => {
    navigate(`/${firstUrlSegment}/${salesOrderNumber}/re-route`);
  };

  if ((error as AxiosError)?.response?.status === 404) {
    return <ExceptionState customTitle="Order Not Found" stateType="empty" />;
  }

  return (
    <>
      <OrderDetails
        order={isFetching || isFetchingParentOrder ? undefined : order}
        parentOrder={
          isFetching || isFetchingParentOrder ? undefined : parentOrder
        }
        onReRoute={handleReRoute}
        onCancel={handleCancel}
        onEdit={handleEdit}
      />
      <Outlet />
    </>
  );
}
