import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { InventoryItemDto } from 'types/inventory';
import { mapDtoToInventoryItem } from './mappers';

export function useInventoryItems(sku: string) {
  return useQuery(
    ['inventoryItems', sku],
    async () => {
      const { data } = await axios.get<InventoryItemDto[]>(
        `/inventory/${encodeURIComponent(sku)}`
      );

      return data;
    },
    {
      select: (orders: InventoryItemDto[]) => orders.map(mapDtoToInventoryItem),
      enabled: Boolean(sku),
    }
  );
}
