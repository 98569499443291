/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useMemo, useState } from 'react';
import { Box, Button, Text } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, FormProvider } from 'react-hook-form';
import ClearIcon from '@mui/icons-material/Clear';
import { parseDate } from 'utils/dateUtils';
import { AddBoxButton } from 'components/AddBoxButton';
import { ButtonGroup } from 'components/ButtonGroup';
import { FormInput } from 'components/FormInput';
import { InlineText } from 'components/InlineText';
import { FormDatePicker } from 'components/FormDatePicker';
import { useConfirmationDialog } from 'hooks/useConfirmationDialog';
import { useUpdatePurchaseOrder } from 'mutations/purchaseOrders';
import { PurchaseOrder } from 'types/purchaseOrders';
import { modifyPurchaseOrderSchema } from 'schemas/modifyPurchaseOrder';
import { OrderLines } from 'features/order-lines/OrderLines';
import { useCrudActionUserFeedback } from 'hooks/useCrudActionUserFeedback';
import { mapFieldDtoErrorToRhfPath } from 'mutations/purchaseOrders/mappers';
import { sendUpdatePurchaseOrderEvent } from 'utils/monitorEvents';
import { useContainerNumbers } from 'hooks/useContainerNumbers';

type Props = {
  order: PurchaseOrder;
  onClose: () => void;
};

export function ModifyPurchaseOrder({ order, onClose }: Props) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { openConfirmationDialog } = useConfirmationDialog();
  const reactHookFormRet = useForm<PurchaseOrder>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: order,
    resolver: yupResolver(modifyPurchaseOrderSchema),
  });
  const {
    handleSubmit,
    getValues,
    setValue,
    formState: { errors, isDirty },
    control,
    register,
  } = reactHookFormRet;
  const {
    containerNumberFields,
    appendContainerNumber,
    removeContainerNumber,
  } = useContainerNumbers(control);

  const addContainerNumberText = useMemo(() => {
    return containerNumberFields.length > 0
      ? 'Add another Container Number'
      : 'Add Container Number';
  }, [containerNumberFields]);

  const confirmAbort = useCallback(
    (callback: () => void) => {
      if (isDirty) {
        openConfirmationDialog({
          title: 'Discard changes',
          message: `Discard changes to #${order.poNumber} before leaving?`,
          onConfirm: callback,
          blockScrollOnMount: false,
          confirmButtonLabel: 'Discard',
        });
      } else {
        callback();
      }
    },
    [isDirty, order.poNumber]
  );

  const { mutateAsync } = useUpdatePurchaseOrder(order.poNumber);

  const handleUpdateOrder = useCrudActionUserFeedback<PurchaseOrder>({
    mutateAsync: () => mutateAsync(getValues()),
    actionType: 'UPDATE',
    successMessage: `Shipping Plan #${order.poNumber} successfully updated`,
    successCallback: () => {
      sendUpdatePurchaseOrderEvent(order.poNumber);
      onClose();
    },
    startCallback: () => {
      setIsSubmitting(true);
    },
    finallyCallback: () => {
      setIsSubmitting(false);
    },
    setFieldError: reactHookFormRet.setError,
    mapFieldDtoErrorToRhfPath,
  });

  const handleAddContainerNumberClick = () =>
    appendContainerNumber({
      containerNumber: '',
    });

  const handleRemoveContainerNumberclick = (idx: number) =>
    removeContainerNumber(idx);

  return (
    <FormProvider {...reactHookFormRet}>
      <Text mb={6} as="h2" variant="pageTitle">
        Modify Shipping Plan #{order.poNumber}
      </Text>
      <form onSubmit={handleSubmit(handleUpdateOrder)}>
        <Box>
          <InlineText variant="boldBlue" fontSize="xl" mr={1}>
            Warehouse ID:
          </InlineText>
          <InlineText fontSize="xl" display={['block', null, 'inline']}>
            {order.warehouseID}
          </InlineText>
        </Box>
        <Box mt={8} w="270px">
          <Text variant="boldBlue" fontSize="xl" mb={2}>
            Requested Delivery Date
          </Text>
          <FormDatePicker
            name="requestedDate"
            defaultValue={parseDate(getValues().requestedDate)}
            onChange={(value: string) =>
              setValue('requestedDate', value, { shouldDirty: true })
            }
            placeholder="Pick date"
            aria-label="Filter"
            variant="flushed"
            error={errors.requestedDate?.message}
            isClearable
          />
        </Box>
        <Text variant="boldBlue" fontSize="xl" mt={5} mb={2}>
          Container Numbers
        </Text>
        {containerNumberFields.map((field, i) => (
          <Box
            display="flex"
            gap={4}
            key={field.id}
            mb={4}
            data-testid={`container-numbers.${i}`}
          >
            <FormInput {...register(`containerNumbers.${i}.containerNumber`)} />
            <Button
              onClick={() => handleRemoveContainerNumberclick(i)}
              fontSize="md"
              variant="redSquareButton"
              title="Remove Container Number"
            >
              <ClearIcon fontSize="inherit" />
            </Button>
          </Box>
        ))}
        <AddBoxButton
          onClick={handleAddContainerNumberClick}
          title={addContainerNumberText}
        >
          {addContainerNumberText}
        </AddBoxButton>
        <Box mt={8}>
          <OrderLines isPurchaseOrder title="SKUs and quantities inbound" />
        </Box>
        <ButtonGroup
          wrapperProps={{
            mt: 8,
            display: 'grid',
            columnGap: 8,
            rowGap: 4,
            gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
          }}
          commonButtonWrapperProps={{ mr: 0, colSpan: 1 }}
          commonButtonProps={{ width: '100%' }}
          buttons={[
            {
              label: 'Update',
              variant: 'cta',
              isLoading: isSubmitting,
            },
            {
              label: 'Cancel',
              handler: () => confirmAbort(onClose),
            },
          ]}
        />
      </form>
    </FormProvider>
  );
}
