/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useState } from 'react';
import { GridItem, SimpleGrid, Text } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, FormProvider } from 'react-hook-form';
import { ButtonGroup } from 'components/ButtonGroup';
import { PurchaseOrder } from 'types/purchaseOrders';
import { schedulePurchaseOrderSchema } from 'schemas/schedulePurchaseOrder';
import { OrderLines } from 'features/order-lines/OrderLines';
import { useCreatePurchaseOrder } from 'mutations/purchaseOrders/useCreatePurchaseOrder';
import { SchedulePurchaseOrderForm } from './components/SchedulePurchaseOrderForm';
import { useCrudActionUserFeedback } from 'hooks/useCrudActionUserFeedback';
import { mapFieldDtoErrorToRhfPath } from 'mutations/purchaseOrders/mappers';
import { sendSchedulePurchaseOrderEvent } from 'utils/monitorEvents';

type Props = {
  onConfirm: (po: PurchaseOrder) => void;
  onAbort: () => void;
};

const sessionStorageKey = 'schedule_po_draft';

export function SchedulePurchaseOrder({ onAbort, onConfirm }: Props) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const draft = sessionStorage.getItem(sessionStorageKey);
  const reactHookFormRet = useForm<PurchaseOrder>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: draft
      ? JSON.parse(draft)
      : { lines: [{ lineNo: '1', orderQty: 1 }] },
    resolver: yupResolver(schedulePurchaseOrderSchema),
  });
  const {
    handleSubmit,
    getValues,
    formState: { isDirty },
  } = reactHookFormRet;

  const confirmAbort = useCallback(
    (callback: () => void) => {
      if (isDirty) {
        sessionStorage.setItem(sessionStorageKey, JSON.stringify(getValues()));
      }

      callback();
    },
    [isDirty]
  );

  const { mutateAsync } = useCreatePurchaseOrder();

  const handleScheduleOrder = useCrudActionUserFeedback<PurchaseOrder>({
    mutateAsync: () => mutateAsync(getValues()),
    actionType: 'CREATE',
    successMessage: 'Shipping Plan successfully scheduled',
    successCallback: () => {
      sendSchedulePurchaseOrderEvent();
      onConfirm(getValues());
      sessionStorage.removeItem(sessionStorageKey);
    },
    startCallback: () => {
      setIsSubmitting(true);
    },
    finallyCallback: () => {
      setIsSubmitting(false);
    },
    setFieldError: reactHookFormRet.setError,
    mapFieldDtoErrorToRhfPath,
  });

  return (
    <FormProvider {...reactHookFormRet}>
      <Text as="h2" variant="pageTitle">
        Create Shipping Plan
      </Text>
      <form onSubmit={handleSubmit(handleScheduleOrder)}>
        <SimpleGrid columns={1} gap={8} mt={{ base: 5, lg: 0 }}>
          <GridItem>
            <SchedulePurchaseOrderForm />
          </GridItem>
          <GridItem>
            <OrderLines isPurchaseOrder title="Line Items" />
          </GridItem>
          <ButtonGroup
            wrapperProps={{
              display: 'grid',
              columnGap: 8,
              rowGap: 4,
              gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
            }}
            commonButtonWrapperProps={{ mr: 0, colSpan: 1 }}
            commonButtonProps={{ width: '100%' }}
            buttons={[
              {
                label: 'Submit',
                variant: 'cta',
                isLoading: isSubmitting,
              },
              {
                label: 'Cancel',
                handler: () => confirmAbort(onAbort),
              },
            ]}
          />
        </SimpleGrid>
      </form>
    </FormProvider>
  );
}
