import { useTenant } from 'queries/tenant/useTenant';
import { useMemo } from 'react';
import { useCopyToClipboard } from './useCopyToClipboard';

export const BASE_CONTACT_URL =
  'https://standvast-team.atlassian.net/servicedesk/customer/portal/11/group/15/create/10171';

export const usePurchaseOrderContact = (poNumbers: string[]) => {
  const { data: tenant } = useTenant();

  const contactUrl = useMemo(() => {
    if (!tenant || !poNumbers.length) return BASE_CONTACT_URL;

    const listPoNumbers = poNumbers.join(',');

    const url = new URL(BASE_CONTACT_URL);
    url.searchParams.append(
      'summary',
      `Schedule Transportation for ${listPoNumbers}`
    );
    url.searchParams.append('customfield_10359', tenant.name);
    url.searchParams.append('customfield_10120', listPoNumbers);

    return url.toString();
  }, [poNumbers, tenant]);

  const { copy } = useCopyToClipboard(contactUrl, {
    toastOptions: {
      status: 'info',
      description: 'Contact URL copied to the clipboard.',
    },
  });

  return {
    copy,
    url: contactUrl,
  };
};
