import axios from 'axios';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { FreightLabelInfo, FreightLabelInfoDto } from 'types/salesOrders';
import { mapDtoToFreightLabelInfo } from './mappers';

export function useSalesOrderLabelDetails(
  soNumber: string,
  options?: UseQueryOptions<FreightLabelInfo>
) {
  return useQuery<FreightLabelInfo>(
    ['labelDetail', soNumber],
    async () => {
      const { data } = await axios.get<FreightLabelInfoDto>(
        `/salesorder/label_details/${encodeURIComponent(soNumber!)}`
      );
      return mapDtoToFreightLabelInfo(data);
    },
    options
  );
}
