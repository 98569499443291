import { useCallback, useEffect } from 'react';
import { Outlet, useSearchParams } from 'react-router-dom';
import { OrdersGrid } from 'features/orders-list/OrdersGrid';
import {
  orderFieldToQuery,
  OrderPageParams,
  queryToOrderField,
} from 'features/orders-list/orderPageParams';
import { SortState } from 'hooks/useGridSorting';
import { SalesOrder } from 'types/salesOrders';
import {
  QueryParamsObject,
  getQueryParamsObject,
  parseFilters,
  parseSort,
  querifyFilters,
  querifySort,
} from 'utils/urlSearchParamsUtils';
import { useStore } from 'contexts/storeContext';
import * as _ from 'lodash-es';
import { focusToField } from 'utils/browserUtils';
import { getLastWeekDateRange } from 'utils/dateUtils';

export function OrdersPage() {
  const lastWeekDateRange = getLastWeekDateRange();
  const [searchParams, setSearchParams] = useSearchParams({
    created_date_range: `${lastWeekDateRange.from}_${lastWeekDateRange.to}`,
    sortBy: '',
    page: '',
    pageSize: '',
  });
  const [state, dispatch] = useStore();

  const updateSearchParams = useCallback(
    (updating: object) =>
      setSearchParams({
        ...getQueryParamsObject(searchParams),
        page: '1',
        ...updating,
      }),
    [searchParams, setSearchParams]
  );

  const onFilterChange = useCallback(
    (filters: QueryParamsObject) => {
      dispatch(_.pick(filters, ['fieldToFocus']));
      updateSearchParams(
        querifyFilters(_.omit(filters, ['fieldToFocus']), orderFieldToQuery)
      );
    },
    [dispatch, updateSearchParams]
  );

  const onSortByChange = useCallback(
    (sorting: SortState<SalesOrder>) => {
      updateSearchParams({ sortBy: querifySort(sorting, orderFieldToQuery) });
    },
    [updateSearchParams]
  );

  const gridParams: OrderPageParams = {
    initialSort: parseSort(searchParams.get('sortBy') || '', queryToOrderField),
    onSortByChange,
    initialPage: parseInt(searchParams.get('page') || '', 10),
    initialPageSize: parseInt(searchParams.get('pageSize') || '', 10),
    onPaginationChange: (pagination) => updateSearchParams(pagination),
    initialFilters: parseFilters(
      getQueryParamsObject(searchParams),
      queryToOrderField
    ),
    onFilterChange,
  };

  useEffect(() => {
    if (state && state.fieldToFocus) {
      focusToField(state.fieldToFocus);
    }
  }, [state]);

  return (
    <>
      <OrdersGrid {...gridParams} />
      <Outlet />
    </>
  );
}
