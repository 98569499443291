import { Radio, RadioGroup, Stack, Text } from '@chakra-ui/react';
import { BigLoading } from 'components/BigLoading';
import { Modal } from 'components/Modal';
import { mapSalesOrderToRerouteReport } from 'mutations/salesOrders/mappers';
import { useSalesOrder } from 'queries/salesOrders';
import { useWarehouses } from 'queries/warehouses/useWarehouses';
import { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { SalesOrderStatus } from 'types/salesOrders';
import { FileType } from 'utils/fileTypes';
import { downloadReport } from 'utils/reportUtils';
import { stringify as csvStringify } from 'csv-stringify/browser/esm/sync';
import { useTenant } from 'queries/tenant/useTenant';

export function ReRouteOrderPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const { salesOrderNumber } = useParams();

  const [warehouse, setWarehouse] = useState('');

  const { data: warehouses, isLoading: isLoadingWarehouses } = useWarehouses();
  const { data: tenant, isLoading: isLoadingTenant } = useTenant();

  const { data: order, isLoading: isLoadingOrder } = useSalesOrder(
    salesOrderNumber,
    {
      onSuccess: (data) => {
        if (
          data.status === SalesOrderStatus.Received ||
          data.status === SalesOrderStatus.ReceivedUpdated
        )
          return;
        close();
      },
    }
  );
  const isLoading = isLoadingWarehouses || isLoadingOrder;

  const urlSegments = location.pathname.split('/');
  const firstUrlSegment = urlSegments[1] ?? '';

  const close = () => navigate(`/${firstUrlSegment}/${salesOrderNumber}`);

  const download = () => {
    if (!order || !tenant) return;
    const data = mapSalesOrderToRerouteReport(order, {
      warehouse,
      tenant,
    });
    downloadReport({
      data: csvStringify(data, {
        header: true,
        columns: Object.keys(data[0]),
      }),
      fileName: `${salesOrderNumber}-Reroute-${warehouse}.csv`,
      fileType: FileType.Csv,
    });
  };

  return (
    <Modal
      title="Reroute Shipment"
      isOpen={true}
      size={['full', null, '6xl']}
      scrollVisible
      onClose={close}
      buttons={[
        {
          label: 'Download',
          isDisabled: isLoading || isLoadingTenant || !warehouse,
          handler: download,
          buttonProps: {
            'aria-label': 'Download Reroute Report',
          },
        },
      ]}
    >
      {isLoading ? (
        <BigLoading />
      ) : (
        <>
          <Text mb={6} fontWeight={500}>
            Check inventory for these SKUs first. Then choose a new fulfillment
            center to ship from.
          </Text>
          <RadioGroup value={warehouse} onChange={(code) => setWarehouse(code)}>
            <Stack spacing={5} direction="row" flexWrap="wrap">
              {warehouses?.map((item) => (
                <Radio
                  key={item.code}
                  value={item.code}
                  isDisabled={item.code === order?.targetDc}
                >
                  {item.displayText}
                </Radio>
              ))}
            </Stack>
          </RadioGroup>
        </>
      )}
    </Modal>
  );
}
