import { useCallback, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { PurchaseOrderGrid } from 'features/purchase-orders/PurchaseOrderGrid';
import {
  poFieldToQuery,
  PurchaseOrderPageParams,
  queryToPOField,
} from 'features/purchase-orders/purchaseOrderPageParams';
import {
  getQueryParamsObject,
  parseFilters,
  parseSort,
  querifyFilters,
  querifySort,
  QueryParamsObject,
} from 'utils/urlSearchParamsUtils';
import { SortState } from 'hooks/useGridSorting';
import { PurchaseOrder } from 'types/purchaseOrders';
import { useStore } from 'contexts/storeContext';
import * as _ from 'lodash-es';
import { focusToField } from 'utils/browserUtils';

export function PurchaseOrderPage() {
  const [searchParams, setSearchParams] = useSearchParams({
    created_date_range: '',
    sortBy: '',
    page: '',
    pageSize: '',
  });
  const [state, dispatch] = useStore();

  const updateSearchParams = useCallback(
    (updating: object) =>
      setSearchParams({
        ...getQueryParamsObject(searchParams),
        page: '1',
        ...updating,
      }),
    [searchParams, setSearchParams]
  );

  const onFilterChange = useCallback(
    (filters: QueryParamsObject) => {
      dispatch(_.pick(filters, ['fieldToFocus']));
      updateSearchParams(
        querifyFilters(_.omit(filters, ['fieldToFocus']), poFieldToQuery)
      );
    },
    [dispatch, updateSearchParams]
  );

  const onSortByChange = useCallback(
    (sorting: SortState<PurchaseOrder>) => {
      updateSearchParams({
        sortBy: querifySort(sorting, poFieldToQuery),
      });
    },
    [updateSearchParams]
  );

  const params: PurchaseOrderPageParams = {
    initialSort: parseSort(searchParams.get('sortBy') || '', queryToPOField),
    onSortByChange,
    initialPage: parseInt(searchParams.get('page') || '', 10),
    initialPageSize: parseInt(searchParams.get('pageSize') || '', 10),
    onPaginationChange: (pagination) => updateSearchParams(pagination),
    initialFilters: parseFilters(
      getQueryParamsObject(searchParams),
      queryToPOField
    ),
    onFilterChange,
  };

  useEffect(() => {
    if (state && state.fieldToFocus) {
      focusToField(state.fieldToFocus);
    }
  }, [state]);

  return <PurchaseOrderGrid {...params} />;
}
