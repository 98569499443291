import {
  ContainerStatus,
  PurchaseOrder,
  PurchaseOrderCondensedDto,
  PurchaseOrderDetailDto,
  PurchaseOrderDto,
  PurchaseOrderLineDto,
  PurchaseOrderSearchResult,
  PurchaseOrderSearchResultDto,
  PurchaseOrderStatus,
} from 'types/purchaseOrders';
import { orderBy } from 'lodash-es';
import { parseContainerStatus } from 'utils/purchaseOrdersUtils';

export function mapDtoToPurchaseOrder(order: PurchaseOrderDto): PurchaseOrder {
  const isCondensed = isCondensedPurchaseOrder(order);
  const lines = isCondensed ? [] : order.purchase_order_lines;
  const hasContainerNumber = (order.container_number || '').trim().length > 0;

  const totalOrderQty = isCondensed
    ? order.total_order_qty
    : calculateTotal(lines, 'order_qty');
  const totalReceivedQty = isCondensed
    ? order.total_received_qty
    : calculateTotal(lines, 'received_qty');
  const totalShortQty = isCondensed
    ? order.total_order_qty
    : calculateTotal(lines, 'short_qty');
  const totalDamagedQty = isCondensed
    ? order.total_order_qty
    : calculateTotal(lines, 'damaged_qty');
  const totalOverageQty = isCondensed
    ? order.total_order_qty
    : calculateTotal(lines, 'overage_qty');
  const statusChangesHistory =
    isCondensed || !order.status_history
      ? []
      : order.status_history.map(({ status, date }) => ({
          // TODO: check if it's necessary to keep separate properties here, like we do for SOs.
          status: status as PurchaseOrderStatus,
          status_date: date,
        }));
  const status = isCondensed
    ? order.latest_status
    : order.status_history?.at(-1)?.status || 'Inbound';
  const statusDate = isCondensed
    ? order.status_date
    : order.status_history?.at(-1)?.date || '';

  return {
    poNumber: order.po_number,
    status: status as PurchaseOrderStatus,
    statusDate,
    createdDate: order.created_date,
    requestedDate: order.requested_delivery_date,
    vendorName: order.vendor_name,
    containerNumber: order.container_number,
    containerNumbers: hasContainerNumber
      ? order.container_number!.split('###').map((container_number) => ({
          containerNumber: container_number,
        }))
      : [],
    warehouseID: order.target_dc,
    totalOrderQty,
    totalReceivedQty,
    totalShortQty,
    totalDamagedQty,
    totalOverageQty,
    totalLines: isCondensed
      ? order.total_po_lines
      : order.purchase_order_lines.length,
    statusChangesHistory,
    deliveryAddress:
      isCondensed || !order.delivery_address
        ? undefined
        : {
            name: order.delivery_address.name,
            address: order.delivery_address.address,
            city: order.delivery_address.city,
            state: order.delivery_address.state,
            zipCode: order.delivery_address.zip_code,
            country: order.delivery_address.country,
            email: order.delivery_address.email,
            phoneNumber: order.delivery_address.phone_number,
          },
    lines: orderBy(
      lines.map((line) => ({
        lineNo: line.line_number,
        sku: line.sku,
        description: line.description,
        orderQty: line.order_qty,
        receivedQty: line.received_qty,
        shortQty: line.short_qty,
        damagedQty: line.damaged_qty,
        overageQty: line.overage_qty,
        unitOfMeasure: line.uom,
        customerItemNumber: line.external_item_number ?? '',
      })),
      ['lineNo']
    ),
    containerStatus: order.container_status,
  };
}

export function mapDtoToPurchaseOrderSearchResult(
  order: PurchaseOrderSearchResultDto
): PurchaseOrderSearchResult {
  return {
    poNumber: order.po_number,
  };
}

function calculateTotal(
  lines: PurchaseOrderLineDto[],
  propertyName:
    | 'order_qty'
    | 'received_qty'
    | 'short_qty'
    | 'damaged_qty'
    | 'overage_qty'
) {
  return lines.map((l) => l[propertyName]).reduce((a, b) => a + b, 0);
}

function isCondensedPurchaseOrder(
  order: PurchaseOrderDto
): order is PurchaseOrderCondensedDto {
  return !Array.isArray((order as PurchaseOrderDetailDto).purchase_order_lines);
}

export function mapContainerStatusList(
  containerNumbers: { containerNumber: string }[],
  containerStatus: string[] = []
): ContainerStatus[] {
  return containerNumbers
    .map(({ containerNumber }, index) => ({
      number: containerNumber,
      status: parseContainerStatus(containerStatus[index]),
    }))
    .sort((a, b) => {
      if (a.status === 'Check-in' && b.status !== 'Check-in') return -1;
      if (a.status !== 'Check-in' && b.status === 'Check-in') return 1;
      if (a.status === b.status) return a.number.localeCompare(b.number);
      return a.status.localeCompare(b.status);
    }) as ContainerStatus[];
}
