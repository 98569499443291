import { Outlet } from 'react-router-dom';
import { SimpleGrid } from '@chakra-ui/react';
import { PendingOrders } from 'features/dashboard/components/PendingOrders/PendingOrders';
import { DashboardCard } from 'components/DashboardCard';
import { InventorySummary } from 'features/dashboard/components/InventorySummary/InventorySummary';
import withLayout from 'hoc/withLayout';
import { CLOSED_STATUSES, PENDING_STATUSES } from 'constants/order-statuses';
import { NetworkOrderStatus } from 'types/networkOrder';
import { OpenNetworkOrders } from 'features/dashboard/components/OpenNetworkOrders/OpenNetworkOrders';
import { ClosedNetworkOrders } from 'features/dashboard/components/ClosedNetworkOrder/ClosedNetworkOrders';
import { getLastMonthDateRange } from 'utils/dateUtils';
import { CONFIG } from 'utils/config';
import { ClosedSalesOrders } from 'features/dashboard/components/ClosedSalesOrders/ClosedSalesOrders';
import { useTenant } from 'queries/tenant/useTenant';

function DashboardComponent() {
  const { data: tenant } = useTenant();
  const lastMonthDateRange = getLastMonthDateRange();
  return (
    <SimpleGrid mx={{ base: 2, xl: 8 }} columns={{ base: 1, lg: 2 }} gap={8}>
      {CONFIG().isNetworkOrderEnabled(tenant) && (
        <>
          <DashboardCard
            title="Open Orders"
            primaryCTA={{
              label: 'View Open Orders',
              href: `/network-orders?created_range=${`${lastMonthDateRange.from}_${lastMonthDateRange.to}`}&status_select=${[
                NetworkOrderStatus.Received,
                NetworkOrderStatus.Processing,
                NetworkOrderStatus.Error,
              ].join(',')}`,
            }}
          >
            <OpenNetworkOrders />
          </DashboardCard>
          <DashboardCard
            title="Closed Orders (last 30 days)"
            primaryCTA={{
              label: 'View Closed Orders',
              href: `/network-orders?created_range=${`${lastMonthDateRange.from}_${lastMonthDateRange.to}`}&status_select=${[
                NetworkOrderStatus.Complete,
                NetworkOrderStatus.Partial,
                NetworkOrderStatus.Cancelled,
              ].join(',')}`,
            }}
          >
            <ClosedNetworkOrders />
          </DashboardCard>
        </>
      )}
      <DashboardCard
        title="Open Shipments"
        primaryCTA={{
          label: 'View Open Shipments',
          href: `/orders?latest_status=${PENDING_STATUSES.join(',')}`,
        }}
      >
        <PendingOrders />
      </DashboardCard>
      <DashboardCard
        title="Closed Shipments (last 30 days)"
        primaryCTA={{
          label: 'View Closed Shipments',
          href: `/orders?latest_status=${CLOSED_STATUSES.join(',')}`,
        }}
      >
        <ClosedSalesOrders />
      </DashboardCard>
      <DashboardCard
        title="Inventory Summary"
        primaryCTA={{
          label: 'View Inventory',
          href: '/inventory',
        }}
      >
        <InventorySummary />
      </DashboardCard>
      <Outlet />
    </SimpleGrid>
  );
}

export const DashboardPage = withLayout(DashboardComponent);
