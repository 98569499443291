import { Box, BoxProps, Tag, Text } from '@chakra-ui/react';
import { SectionCollapsable } from 'components/SectionCollapsable';
import { Shimmer } from 'components/Shimmer';
import { ContainerStatus } from 'types/purchaseOrders';

type Props = BoxProps & {
  data?: ContainerStatus[];
};

export function ContainerStatusList({ data, ...props }: Props) {
  const totalCheckedIn = data?.filter(
    (container) => container.status === 'Checked-in'
  ).length;

  return (
    <Box {...props}>
      <Text variant="boldBlue" fontSize="xl" mr={1}>
        Containers
      </Text>
      <Shimmer w="160px" h="30px" isLoaded={!!data}>
        <SectionCollapsable
          title={`${totalCheckedIn} of ${data?.length} containers checked in`}
          buttonProps={{
            justifyContent: 'flex-start',
            gap: 1,
            size: 'sm',
            width: 'auto',
            color: 'inherit',
            'aria-label': 'Toggle Visible Containers',
          }}
          titleProps={{
            fontSize: 'md',
            color: 'inherit',
            fontWeight: 'normal',
          }}
        >
          <Box as="ul" mt={2}>
            {data?.map(({ status, number }) => {
              const colorScheme = status === 'Checked-in' ? 'green' : 'gray';
              return (
                <Box
                  as="li"
                  key={number}
                  listStyleType="none"
                  display="flex"
                  alignItems="center"
                  mb={2}
                >
                  <Text mr={2}>{number}</Text>
                  <Tag
                    aria-label={`Tag ${colorScheme}`}
                    size="lg"
                    fontSize="lg"
                    variant="solid"
                    colorScheme={colorScheme}
                  >
                    {status}
                  </Tag>
                </Box>
              );
            })}
          </Box>
        </SectionCollapsable>
      </Shimmer>
    </Box>
  );
}
