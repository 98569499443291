import { ForwardedRef, forwardRef } from 'react';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  SelectProps,
  Tooltip,
} from '@chakra-ui/react';
import { Select, OptionBase } from 'chakra-react-select';
import { useFormContext } from 'react-hook-form';
import get from 'lodash-es/get';
import HelpIcon from '@mui/icons-material/Help';
import AsteriskIcon from './AsteriskIcon';

export type FormSelectMultiOnChangeEvent = {
  target: {
    currentValue: OptionSelect[];
    name: string;
    value: OptionSelect[];
  };
};

export type FormSelectMultiProps = Omit<SelectProps, 'onChange'> & {
  label?: string;
  placeholder?: string;
  error?: string;
  hideLabel?: boolean;
  tooltip?: string;
  isRequired?: boolean;
  options: OptionSelect[];
  onChange: (event: FormSelectMultiOnChangeEvent) => void;
};

export interface OptionSelect extends OptionBase {
  label: string;
  value: string;
}

function FormSelectMultiInner(
  {
    label,
    placeholder,
    error,
    options,
    hideLabel,
    tooltip,
    isRequired,
    onChange,
    ...props
  }: FormSelectMultiProps,
  _: ForwardedRef<any>
) {
  const {
    formState: { errors: formErrors },
    setValue,
    getValues,
  } = useFormContext();

  const errorMessage =
    error ?? (get(formErrors, props.name ?? '')?.message as string);

  const currentValue = get(getValues(), props.name ?? '');

  const handleChangeValue = (data: any) => {
    if (onChange) {
      onChange({
        target: {
          currentValue,
          name: props.name,
          value: data,
        },
      } as any);
    } else {
      setValue(props.name ?? '', data, {
        shouldDirty: true,
        shouldValidate: true,
      });
    }
  };

  return (
    <FormControl variant="floating" isInvalid={!!errorMessage}>
      {label && !hideLabel ? (
        <FormLabel display="flex" opacity={props.isDisabled ? 0.4 : 1} mb={1}>
          {label}
          {tooltip ? (
            <Tooltip label={tooltip}>
              <HelpIcon
                fontSize="inherit"
                sx={{
                  marginLeft: '5px',
                  width: '1.3em',
                  height: '1.3em',
                }}
              />
            </Tooltip>
          ) : null}
          {isRequired ? <AsteriskIcon width="6px" /> : null}
        </FormLabel>
      ) : null}

      <Select
        isMulti
        menuPosition='fixed'
        options={options}
        placeholder={placeholder}
        closeMenuOnSelect={false}
        aria-label={label}
        value={currentValue}
        onChange={handleChangeValue}
      />
      {errorMessage && (
        <FormErrorMessage mb={2}>{errorMessage}</FormErrorMessage>
      )}
    </FormControl>
  );
}

export const FormSelectMulti = forwardRef(FormSelectMultiInner);
